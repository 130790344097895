.campaign-page-card {
  background: #F7F4F2 0% 0% no-repeat padding-box;
}

.header-countdown {
  color: var(--textPrimary);
}

.header-countdown .countdown-item {
  margin: 0.5rem;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #EAE0D9 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 30px;
}

.header-countdown .countdown-item .countdown-number {
  font: "DigitalNumbers";
}

.header-countdown .countdown-item .countdown-title {
  font-size: 12px;
}

.activity-section .activity-card .activity-item-block {
  display: flex;
}

.activity-section .activity-card .activity-author-bottom,
.activity-section .activity-card .activity-author-type {
  font-size: 14px;
}

.activity-section .activity-card .activity-author-bottom .activity-author-name,
.activity-section .activity-card .activity-author-type {
  color: var(--textSecondary);
}

.action-row {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.participate-button button {
  width: 11rem;
}

.activity-card .activity-image.listing-card-image {
  width: 85px;
  height: 130px;
  background-color: #E6E5E2;
  border-radius: 5px;
}

.action-row .index-circle {
  width: 60px;
  height: 60px;
  background: #F7F4F2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border-radius: 50%;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}

.action-row .action-card {
  min-height: 100px;
  margin-top: unset;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-row .action-title,
.action-row .action-description,
.action-row .action-status {
  margin: 0.5rem 0;
}

.action-row .action-title {
  font-weight: bold;
  font-size: 18px;
}

.action-row .action-description {
  color: var(--textSecondary);
}

.action-row .action-status {
  color: var(--primaryColor);
}

.action-row button {
  font-size: 12px;
  width: 130px;
}

.manage-listings-card {
  padding: 1rem;
}

.manage-listings-card hr {
  width: 100%;
  margin-top: 0.4rem;
}

.manage-listings-header .manage-listings-title .listings-title {
  font-weight: bold;
  font-size: 40px;
}

.manage-listings-card .contribution-header-title {
  font-weight: 600;
}

.manage-listings-card .contribution-header-title .contribution-length {
  color: var(--textSecondary);
}

.manage-listings-card .contributions-left {
  color: var(--primaryColor);
  font-weight: 600;
}

.manage-listings-card .manage-listing-card {
  width: 48%;
  margin-right: 1rem;
}

.manage-listings-card .manage-listing-card .edit-listing {
  color: var(--textSecondary);
  position: absolute;
  bottom: 10%;
  cursor: pointer;
}

.manage-listings-card .manage-listing-card .listing-description {
  font-size: 14px;
}

.contribution-review {
  padding: 0.2rem 0.5rem;
  background-color: var(--primaryColor);
  text-align: center;
  border-radius: 20px;
}

.contribution-review-link,
.contribution-review-link:hover {
  color: #FFFFFF;
}

.activity-item .activity-content-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 54px;
}

.activity-item .activity-right {
  position: relative;
}

.activity-item .activity-stats {
  position: absolute;
  bottom: 0rem;
  width: 100%;
}

.activity-action-card {
  font-size: 25px;
  background: #F7F4F2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px #00000059;
  border: 0.5px solid #E3E3E3;
  border-radius: 10px;
  opacity: 1;
  margin: 1rem;
  padding: 1rem;
}

.activity-action-card__step-counter {
  color: var(--primaryColor);
  font-weight: 600;
}

/* Media Queries */
@media only screen and (max-width: 540px) {
  .campaign-page-card .institute-header {
    display: block;
  }

  .campaign-page-card .institute-header .header-details {
    justify-content: unset;
    margin-left: 1rem;
  }

  .activity-section .activity-card .activity-item-block {
    display: block;
  }

  .manage-listings-header .manage-listings-title {
    margin: 1rem;
  }

  .manage-listings-header .manage-listings-title .listings-title {
    font-size: 30px;
  }

  .manage-listings-card .contribution-header-title,
  .manage-listings-card .contributions-left {
    font-size: 14px;
  }

  .manage-listings-card .manage-listing-card {
    width: 100%;
  }
}