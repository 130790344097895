.p-x-50 {
  padding: 0 50px;
}
.output-information-details {
  .create-output-name {
    height: 40px;
    margin: 30px 0;
    .MuiFormControl-root {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .output-type-visibility {
    margin: 30px 0;
    height: 40px;
    .create-output-type {
      width: 47%;
    }
    .create-output-visibility {
      width: 47%;
      margin-left: 42px;
    }
  }
  .create-output-description {
    .MuiFormControl-root {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      .MuiOutlinedInput-multiline {
        min-height: 100px;
      }
      .MuiInputBase-root {
        align-items: unset;
      }
    }
  }
}
.create-output-status {
  padding-top: 40px;
  // padding-bottom: 30px;
  .output-status-description {
    p {
      color: #999999;
    }
  }
  .create-output-status-buttons {
    // border: 1px solid red;
    height: fit-content;
  }
}

.create-output-logo {
  display: flex;
  // border: 1px solid green;
  // width: 100%;
  align-items: center;
  > .section-card {
    width: 70%;
    // border: 1px solid blue;
    height: fit-content;
  }
  > .card-image-preview {
    margin-left: 50px;
    // border-radius: 50%;
    width: 120px;
    height: 120px;
    // border: 1px solid #999999;
    box-shadow: 0 3px 5px #0000000d;
    img {
      // border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }
}
