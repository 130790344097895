.responsiveness-only {
    display: none;
  }
  .contribute-section {
    margin: 100px 0 0 0;
    display: flex;
    width: 100%;
    min-height: 100vh;
    .left-div {
      width: 25%;
      background: #ffffff;
      .left-title {
        border-bottom: 1px solid #00000029;
        margin: 30px 25px;
        .publications {
          display: flex;
          img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
          }
          .profile {
            padding-left: 10px;
            h1 {
              font-size: 16px;
            }
          }
          .profile > h1 {
            font-weight: 600;
          }
          .profile-info {
            display: flex;
            img {
              width: 19px;
              height: 19px;
            }
            h1 {
              font-size: 14px;
              color: #707070;
              padding-left: 5px;
            }
          }
        }
        .contribution-timer {
          display: flex;
          justify-content: center;
          padding: 31px 0;
          p {
            font-size: 13px;
            color: #707070;
          }
          .count-down {
            padding-top: 10px;
            background: #f7f4f2;
            margin: 5px;
            border-radius: 30px;
            width: 42px;
            height: 61px;
            text-align: center;
            align-items: center;
            box-shadow: inset 0px 3px 6px #00000029,
              0px 1px 2px #00000029;
            span {
              font-size: 13px;
            }
            p {
              font-size: 8px;
            }
          }
        }
      }
      .left-body {
        p {
          font-size: 13px;
          margin: 10px 0;
        }
        h1 {
          font-size: 16px;
          font-weight: 600;
        }
        .contribute-step {
          padding: 6px 20px;
          button {
            text-align: start;
            background: none;
            border: none;
            outline: none;
          }
        }
        .exit-button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0;
          button {
            // margin: 20px auto;
            background: transparent;
            border: none;
            color: #f38f46;
            font-size: 14px;
          }
        }
      }
    }
    .right-div {
      width: 75%;
      height: 100%;
      padding: 20px 10px;
      .action-step-header {
        display: flex;
        .add-listing-button {
          padding: 0 10px 0 12px;
          text-align: start;
          button {
            width: 170px;
            height: 40px;
            color: #ffffff;
            border: 1px solid #f38f46;
            background: #f38f46;
            font-size: 16px;
            border-radius: 7px;
            box-shadow: 0px 3px 5px #00000040;
            outline: none;
            &:hover {
              background: transparent;
              color: #f38f46;
            }
          }
        }
      }
      .current-step {
        padding: 1rem;
        width: 89%;
        > h1 {
          font-size: 16px !important;
          font-weight: 600;
          text-align: start;
        }
        > p {
          text-align: start;
        }
      }
      > .inside-content {
        margin: auto;
        padding: 100px;
        text-align: center;
        img {
          width: 367px;
          height: 233px;
        }
        button {
          border: none;
          background: #f38f46;
          outline: none;
          width: 200px;
          height: 40px;
          box-shadow: 0px 3px 5px #00000040;
          border-radius: 6px;
          color: #ffffff;
          font-size: 14px;
        }
        p {
          padding: 100px 0 20px 0;
        }
      }
      .contributions-content {
        padding: 30px 0;
        text-align: start;
  
        > p {
          margin-left: 10px;
          font-size: 16px;
          text-align: start;
        }
        .contribution-cards-content {
          display: flex;
          flex-wrap: wrap;
          .contribution-card-area {
            width: 200px;
            border-radius: 10px;
            margin: 10px 10px 10px 10px;
          }
        }
      }
      .endSaveButtons {
        padding-bottom: 50px;
        width: 96%;
        padding: 0 20px 50px 20px;
  
        .cancel-button {
          color: black;
          justify-content: right;
          justify-items: right;
          align-content: flex-end;
          align-items: flex-end;
          margin-left: auto;
        }
      }
  
      .behalf-block {
        border-radius: 10px;
        margin: 1rem;
        box-shadow: 0px 2px 4px #00000029;
  
        .behalf-header {
          padding: 1rem;
          border-bottom: 1px solid #a3a3a3;
          background-color: #ffffff;
          border-radius: 10px 10px 0 0;
        }
  
        .behalf-participant {
          padding: 1rem;
        }
      }
    }
  }
  
  @media (max-width: 992px) {
    .responsiveness-only {
      display: block;
      height: 100%;
    }
    .not-show-step {
      display: none;
    }
    .contribute-section {
      margin: 80px 0 0 0;
      display: block;
      .left-div {
        width: 100%;
        .left-title {
          margin: 0 0;
          padding: 20px;
          display: flex;
          width: 100%;
          .publications {
            margin: auto 0;
          }
          .contribution-timer {
            display: flex;
            margin-left: auto;
            padding: 0;
            p {
              margin-top: auto;
            }
          }
        }
        .left-body {
          background: #ffffff;
          max-height: 60%;
          overflow: scroll;
          box-shadow: 0px 2px 4px #0000000d;
          width: 100%;
          z-index: 2;
          .exit-button {
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            justify-items: center;
            button {
              margin: 50px 0;
              background: transparent;
              border: none;
              color: #f38f46;
              font-size: 14px;
            }
          }
        }
      }
      .responsiveness-only .arrow-down-dropdown {
        height: 20px;
        button {
          width: 100%;
          height: 20px;
          margin: auto;
          border: none;
          background-color: #f38f46;
          color: #ffffff;
          outline: none;
        }
        button svg {
          font-size: 30px;
          margin-top: -12px;
        }
      }
      .right-div {
        width: 100%;
        height: 85%;
        padding-left: 0;
        padding-right: 0;
  
        text-align: center;
        .inside-content {
          padding: 0;
          margin: 0;
          img {
            margin-top: 100px;
            width: 252px;
            height: 160px;
          }
        }
        .contributions-content {
          padding: 30px 20px;
          > p {
            font-size: 13px;
            text-align: start;
            margin-left: 0;
          }
          .contribution-cards-content {
            display: block;
            .contribution-card-area {
              width: 100%;
              border-radius: 10px;
              margin: 10px 10px 0 0;
            }
          }
        }
  
        .endSaveButtons {
          width: 100%;
          padding: 0 20px 50px 0;
  
          .cancel-button {
            color: black;
            justify-content: right;
            justify-items: right;
            align-content: flex-end;
            align-items: flex-end;
            margin-left: auto;
          }
        }
      }
    }
  }
  
  .listing-card-ItemCard {
    margin: 20px 16px 30px 0;
    padding: 20px;
    text-align: start;
    width: 313px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px #0000000d;
    background: #ffffff;
    h1 {
      font-size: 16px;
      font-weight: 600;
      color: #424242;
    }
    p {
      color: #4242429c;
      font-size: 12px;
    }
    .edit-listing > button {
      border: 1px solid #f38f46;
      font-size: 13px;
      background: transparent;
      color: #f38f46;
      border-radius: 7px;
      outline: none;
      &:hover {
        background: #f38f46;
        color: #ffffff;
      }
    }
  }
  
  @media (max-width: 425px) {
    .contribute-section {
      .left-div {
        .left-title {
          .publications {
            img {
              width: 40px;
              height: 40px;
            }
            .profile {
              h1 {
                font-size: 13px;
              }
              .profile-info {
                img {
                  width: 19px;
                  height: 19px;
                }
                h1 {
                  font-size: 11px;
                  color: #707070;
                  padding-left: 5px;
                }
              }
            }
          }
          .contribution-timer {
            .count-down {
              padding-top: 2px;
              width: 30px;
              height: 45px;
              span {
                font-size: 10px;
              }
              p {
                font-size: 8px;
              }
            }
          }
        }
      }
      .right-div {
        padding: 0;
        .current-step {
          padding: 1rem;
          > h1 {
            font-size: 13px;
          }
          > p {
            font-size: 13px;
          }
        }
        .endSaveButtons {
          width: 90%;
          margin: auto;
          padding: 0;
          padding-bottom: 50px;
  
          .cancel-button {
            color: black;
            justify-content: right;
            justify-items: right;
            align-content: flex-end;
            align-items: flex-end;
            margin-left: auto;
          }
  
          .button-div {
            button {
              width: 100%;
              height: 45px;
              margin: 7px 0;
            }
          }
        }
      }
    }
    .complete-button {
      display: block !important;
    }
    .manage-listings-card .contribution-header-title,
    .manage-listings-card .contributions-left {
      font-size: 12px !important;
    }
    .action-step-header {
      display: block !important;
      .current-step {
        width: 100% !important;
        padding: 1rem;
      }
  
      .add-listing-button {
        padding: 0 !important;
        margin: 10px 0 !important;
        text-align: center !important;
        width: 100% !important;
        button {
          font-size: 13px;
          width: 90% !important;
          height: 35px;
          border-radius: 7px;
          box-shadow: none;
        }
      }
    }
  
    .edit-listing > button {
      width: 100%;
      height: 35px;
    }
  
    .listing-card-ItemCard {
      width: 100%;
      margin-right: 0;
      h1 {
        font-size: 13px;
      }
      p {
        font-size: 12px;
      }
    }
  }