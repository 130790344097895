.campaign-detail-page {
  height: 100vh;
}

.main {
  position: relative;
  top: 100px;
  margin: 0 1rem;
}

.main .title {
  display: flex;
  padding: 50px 0;
}

.activity-card {
  margin: 30px 0;
  background: #ffffff;
  border-radius: 10px;
  /* padding: 10px 40px; */
  box-shadow: 0px 2px 4px #00000029;
}

.activity-card__heading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  padding-top: 10px;
  border-bottom: 1px solid #cccccc;
}

.activity-card__heading h5 {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
}
.activity-card__heading span {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  color: #a3a3a3;
}

/* .activity-card__main {
    margin: 2rem 0;
} */

.activity-card__main form {
  width: 100%;
  margin: 2rem 0;
}

.inputLeft50 {
  width: calc(50% - 8px);
  margin-right: 8px !important;
}

.inputRight50 {
  width: calc(50% - 8px);
  margin-left: 8px !important;
}
.input100 {
  width: 100%;
  margin: 8px 0;
}

/* Small devices (landscape phones, 320px and up) */
@media (min-width: 320px) {
  .main .title {
    padding: 16px 0;
  }
  .subheader {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
  }
  .subheader .title h1 {
    font-size: 1.2rem !important;
  }
  .subheader .edit-button {
    display: flex;
    justify-content: center;
  }
  .inputRight50 {
    width: 100%;
    margin: 8px !important;
  }
  .inputLeft50 {
    width: 100%;
    margin: 8px !important;
  }
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .main .title {
    display: flex;
    padding: 50px 0;
  }

  .subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .subheader .edit-button {
    display: flex;
    justify-content: flex-end;
  }

  .inputLeft50 {
    width: calc(50% - 8px);
    margin: 0 8px 0 0 !important;
  }

  .inputRight50 {
    width: calc(50% - 8px);
    margin: 0 0 0 8px !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1600px) {
}
