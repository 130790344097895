.library-search {
  height: 50px;
  width: 100%;
  margin: 0 auto;
  margin-top: 3rem;
}

.library-search .input-group {
  padding: 0 2rem;
}

.library-search .input-group input {
  background-color: #FFFFFF;
}

.library-search .input-group .input-group-append #search-btn {
  background-color: var(--primaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.library-search .input-group .input-group-append #search-btn img {
  width: 20px;
}

.library-items {
  margin: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.library-item {
  display: flex;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px #00000033;
  border-radius: 10px;
  margin: 1rem;
  padding: 0.5rem;
  width: calc(25% - 2rem);
}

.library-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 75px; */
}

.library-item__icon img {
  width: 50px;
  border-radius: 10px;
}

.library-item__text {
  width: 85%;
}

.library-item__title {
  color: #4B515D;
}

.library-item__title,
.library-item__description {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.library-item__bottom-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--textSecondary);
}

.library-item__date,
.library-item__description {
  color: var(--textSecondary);
  font-size: 13px;
}

.library-item__title,
.library-item__description {
  padding-right: 1rem;
}

.library-item__title,
.library-item__description,
.library-item__more {
  cursor: pointer;
}

.library-item__title,
.library-item__date,
.library-item__description,
.library-item__more {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.library-item__more button {
  outline: none;
  color: var(--textSecondary);
}

.library-item__more .dropdown-toggle::after {
  display: none;
}

.item-description-modal .item-description__title {
  font-weight: bold;
  margin-top: 2rem;
}

.item-description-modal .item-description__date {
  font-size: 13px;
  color: var(--textSecondary);
  margin-bottom: 1.5rem;
}

.item-description-modal .item-description__type {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-size: 13px;
}

.item-description-modal .item-description__type img {
  width: 24px;
}

.item-description-modal .item-description__title,
.item-description-modal .item-description__date,
.item-description-modal .item-description__description,
.item-description-modal .item-description__type {
  margin-left: 2rem;
  margin-right: 2rem;
}

.item-description-modal .item-description__type__title {
  color: var(--textSecondary);
}

.item-description-modal .item-description__hr {
  width: 100%;
  border-width: 1px;
  margin-bottom: 0;
}

.item-description-modal .item-description__action-buttons {
  display: flex;
  margin: 0 2rem 1rem;
}

.item-description-modal .item-description__action-buttons button {
  border: 1px solid #f38f46;
  font-size: 13px;
  background: transparent;
  color: #f38f46;
  border-radius: 7px;
  outline: none;
  margin-right: 1rem;
}

.item-description-modal .item-description__action-buttons button:hover {
  background: #f38f46;
  color: #ffffff;
}

.item-description-modal .item-description__button-close {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 2rem;
}

.library-item-dropdown .dropdown-item {
  font-size: 13px;
  padding: 0.25rem 1rem;
}

@media (max-width: 1024px) {
  .library-item {
    width: calc(33% - 2rem);
  }

  .library-item__text {
    width: 80%;
  }
}

@media (max-width: 550px) {
  .library-search {
    margin-top: 1rem;
    height: 30px;
  }

  .library-search .input-group {
    padding: 0 0.5rem;
  }

  .library-search .input-group input,
  .library-search .input-group .input-group-append #search-btn {
    height: 36px;
  }

  .library-search .input-group .input-group-append #search-btn img {
    width: 16px;
  }

  .library-items {
    margin: 1rem 0;
  }

  .library-item {
    width: calc(100% - 1rem);
    margin: 0.5rem;
  }
}