.participant-action {
    background-color: #f7f4f2;
  
    // ========= MAIN NAV ========= //
    .main-nav {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      height: 62px;
      display: flex;
      align-items: center;
      background-color: #f39550;
      color: #fff;
      padding: 0 1.5rem;
  
      .link-wrapper {
        width: fit-content;
  
        button {
          font-size: 16px;
          background: none;
          border: none;
          padding: 0;
          color: #fff;
          display: flex;
          align-items: center;
          margin-right: 1rem;
  
          svg {
            fill: #fff;
          }
        }
      }
  
      .wrapper {
        flex-grow: 1;
  
        P {
          font-size: 24px;
          margin-bottom: 0;
          text-align: center;
  
          @media (max-width: 576px) {
            font-size: 20px;
            text-align: left;
          }
        }
      }
    }
  
}
  