.club-settings-container {
  width: 100%;
  padding-top: 6rem;
}

.club-settings-mobile-container {
  display: none;
}

.club-settings-container .input-group > *,
.club-settings-container .input-group #search-btn,
.club-settings-container .input-group #clear-search-btn {
  background-color: #ffffff;
}

.club-settings-container .input-group #clear-search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1.5px solid #a8a8a8;
  border-bottom: 1.5px solid #a8a8a8;
}

.club-settings-sidenav {
  width: 250px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000029;
  font-size: 16px;
  padding-top: 3rem;
  padding: 0 auto;
  text-align: left;
  height: calc(100vh - 6rem);
  position: sticky;
  top: 6rem;
}

.club-settings-content {
  padding: 3rem 2rem;
  width: calc(100% - 250px);
}

.club-settings-content-title {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 2rem;
}

.pagination-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-bar select {
  width: 60px;
}

.pagination-bar .pages-nav img {
  height: 19px;
  margin: 1rem;
  cursor: pointer;
}

.mobile-pagination {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .club-settings-container {
    display: none;
  }

  .club-settings-mobile-container {
    display: block;
  }

  .club-settings-mobile {
    padding: 6rem 1rem 1rem;
    background: #ffffff;
    box-shadow: 0px 2px 4px #00000059;
  }

  .club-settings-mobile__menu-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-nav {
    padding: 1rem 1rem;
    background: #ffffff;
    box-shadow: 0px 2px 4px #00000059;
  }

  .mobile-nav .container-fluid {
    padding: unset;
  }

  .mobile-nav .nav-link.side-nav-item {
    display: flex;
    padding-left: 0;
  }

  .mobile-nav .nav-link.side-nav-item img {
    width: 20px;
    margin-right: 1rem;
  }

  .mobile-nav .nav-link.side-nav-item__active {
    border-left: unset;
  }

  .mobile-club-settings-items {
    margin-top: 2rem;
  }

  .mobile-club-settings-items .mobile-club-settings-item {
    padding: 1rem;
    border-bottom: 1px solid #e2e0dc;
  }

  .swipe-action > * {
    flex: unset !important;
  }

  .swipeable-list-item__content {
    display: block !important;
  }

  .mobile-club-settings-items .mobile-club-settings-item .mobile-entity {
    display: flex;
    width: 100%;
  }

  .mobile-club-settings-items
    .mobile-club-settings-item
    .mobile-entity
    .mobile-avatar {
    margin-right: 1rem;
  }

  .mobile-club-settings-items
    .mobile-club-settings-item
    .mobile-entity
    .mobile-entity-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .mobile-club-settings-items
    .mobile-club-settings-item
    .mobile-entity
    .mobile-entity-info
    .mobile-entity-name {
    font-weight: bold;
  }

  .mobile-club-settings-items
    .mobile-club-settings-item
    .mobile-entity
    .mobile-entity-info
    .mobile-entity-type {
    font-size: 13px;
    color: var(--textSecondary);
    font-style: italic;
  }

  .mobile-club-settings-items
    .mobile-club-settings-item
    .mobile-entity
    .mobile-entity-info
    .mobile-entity-status {
    font-size: 11px;
  }

  .mobile-club-settings-items
    .mobile-club-settings-item
    .mobile-entity
    .mobile-entity-last_club {
    font-size: 11px;
    color: var(--textSecondary);
  }

  .pagination-bar {
    justify-content: center;
  }

  .pagination-bar .rows-per-page,
  .pagination-bar .current-items {
    display: none;
  }

  .mobile-pagination {
    display: block;
  }
}
