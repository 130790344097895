.activityDetails {
  border-radius: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.activityDetails > .activityDetailsDescription {
  display: flex;
  background: #ffffff;
  padding: 50px;
  border-radius: 10px 10px 0 0;
}

.activityDetails > .activityDetailsDescription > .profilePicture {
  width: 250px;
  height: 250px;
}

.activityDetails
  > .activityDetailsDescription
  > .profilePicture
  > .avatar
  > h1 {
  text-align: center;
  padding-top: 60px;
  font-size: 60px;
}

.activityDetails .descriptionDetails .responsiveness {
  display: none;
}

.activityDetails
  > .activityDetailsDescription
  > .profilePicture
  > .avatar
  > img {
  background: #e8daca 0% 0% no-repeat padding-box;
  width: 230px;
  height: 230px;
  border-radius: 50%;
}
.activityDetails > .activityDetailsDescription > .descriptionDetails {
  height: 250px;
  padding: 10px 20px;
}

.activeIndicator {
  max-width: 10px;
  width: 10px;
  height: 10px;
  background-color: #10a728;
  border-radius: 50%;
}

.inActiveIndicator {
  max-width: 10px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

.activityDetails
  > .activityDetailsDescription
  > .descriptionDetails
  > .descriptionDetailsHeader
  > .statusDiv {
  margin: auto;
  margin-top: -3px !important;
}

.descriptionDetails .descriptionDetailsHeader .statusDiv .activeDiv {
  text-transform: lowercase;
  height: 10px;
  display: flex;
  align-items: center;
  max-width: 150px;
  padding-left: 0;
  margin: 0;
}

.descriptionDetails
  .descriptionDetailsHeader
  .statusDiv
  .activeDiv
  > h4::first-letter {
  text-transform: uppercase;
}

.descriptionDetails .descriptionDetailsHeader .statusDiv .activeDiv > h4 {
  font-size: 18px;
  margin-left: 10px;
  margin-top: 7px;
}

.descriptionDetails .descriptionDetailsHeader .statusDiv > .name {
  width: 500px;
  height: 10px;
  display: flex;
  align-items: center;
}

.descriptionDetails .descriptionDetailsHeader .statusDiv > .name > img {
  height: 23px;
  width: 23px;
  border-radius: 50%;
}

.defaultLabel {
  height: 10px;
  display: flex;
  align-items: center;
  color: #a3a3a3;
  font: italic normal normal 17px/23px Open Sans;
}

.descriptionDetails .statusDiv > .name h1 {
  width: 100%;
  font-size: 18px;
  margin: 7px 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.descriptionDetails .details {
  padding-top: 20px;
}

.descriptionDetails .details > h1 {
  font-size: 16px;
  font-weight: 600;
}

.descriptionDetails .details > p {
  font-size: 16px;
  line-height: 26px;
  text-align: left;
}

.activityDetails .activityDetailsDates {
  padding: 50px;
}

.calendarIcon {
  width: 2px !important;
  display: flex;
  align-items: center;
}

.borderRight {
  border-right: 2px solid #f2ebe5;
}

.dateDetailsItem {
  padding-right: 50px !important;
}

@media (max-width: 599px) {
  .activityDetails {
    border-radius: 0 !important;
    box-shadow: none;
  }

  .activityDetails > .activityDetailsDescription {
    display: block;
    background: transparent;
    padding: 0;
    border-radius: 0;
  }

  .activityDetails > .activityDetailsDescription > .descriptionDetails {
    height: 100%;
    padding: 0;
  }

  .activityDetails
    > .activityDetailsDescription
    > .descriptionDetails
    > .descriptionDetailsHeader {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #e2e0dc;
  }

  .descriptionDetails .statusDiv .activeDiv {
    text-transform: lowercase;
    height: 10px;
    align-items: center;
    max-width: 150px;
    padding-left: 12px !important;
    margin: 0;
  }
  .descriptionDetails .statusDiv > .name {
    width: 250px !important;
    height: 10px;
    align-items: left;
  }

  .descriptionDetails .statusDiv > .name h1 {
    font-size: 13px;
  }

  .details h1,
  .details p {
    font-size: 13px !important;
  }

  .activityDetails
    > .activityDetailsDescription
    > .descriptionDetails
    > .descriptionDetailsHeader
    > .statusDiv {
    margin: auto;
    margin-top: 0px !important;
    /* display: block; */
    width: 100%;
    padding-left: 0 !important;
  }

  .activityDetails .activityDetailsDates {
    display: none;
  }

  .activityDetails .activityDetailsDescription .biggerScreens {
    display: none;
  }

  .activityDetails .descriptionDetails .responsiveness {
    display: block;
  }

  .activityDetails .descriptionDetails .profilePicture .avatar > img {
    background: #e8daca 0% 0% no-repeat padding-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .descriptionDetails .descriptionDetailsHeader .statusDiv .activeDiv > h4 {
    font-size: 13px;
    margin-left: 10px;
    margin-top: 7px;
  }

  .defaultLabel {
    height: 10px;
    display: flex;
    align-items: center;
    color: #a3a3a3;
    font: italic normal normal 17px/23px Open Sans;
    font-size: 13px;
  }
}
