.alphabet-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  background: #e8e7e3 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px #00000029;
  border-radius: 32px;
  padding: 0 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.filter-option {
  padding: 0 0.5rem;
  border-radius: 22px;
  cursor: pointer;
}

#selected-option {
  color: #ffffff;
  background-color: var(--primaryColor);
}

.authors-list {
  display: flex;
  flex-wrap: wrap;
}

.output-contribution-card {
  width: 320px;
  height: 120px;
  margin: 0 1rem 1rem 0;
  padding: 1rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.output-contribution-link,
.output-contribution-link:hover {
  color: var(--textPrimary);
  text-decoration: none;
}

.output-contribution-card .member-avatar {
  margin-right: 1rem;
  width: 80px;
  height: 80px;
}

.output-contribution-card .member-avatar img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.output-contribution-card
  .output-contribution-details
  .output-contribution-subtitle {
  color: var(--textSecondary);
  font-size: 12px;
}

.author-bio-card {
  width: 100%;
  margin-top: 2rem;
  padding: 2rem;
  background: #f7f4f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
}

.author-bio-card-details {
  display: flex;
}

.author-bio-card-details .card-details-left {
  margin-right: 2rem;
}

.author-bio-card-details
  .card-details-left
  .author-profile {
  margin-bottom: 2rem;
}

.author-bio-card-details
  .card-details-left
  .author-profile
  .member-avatar,
.author-bio-card-details
  .card-details-left
  .author-profile
  .member-avatar
  img {
  width: 250px;
  height: 250px;
}

.author-bio-card-details
  .card-details-left
  .author-profile
  .member-avatar
  img {
  object-fit: cover;
}

.author-bio-card-details
  .card-details-left
  .author-profile
  .member-avatar
  .member-initials {
  font-size: 96px;
}

.author-bio-card-details
  .card-details-left
  .author-profile
  .author-social-links {
  margin-top: 2rem;
}

.author-bio-card-details .card-details-right .author-name {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 2rem;
}

.author-bio-card-details
  .card-details-right
  .author-name
  .author-status {
  font-size: 14px;
  font-weight: normal;
}

.author-bio-card-details
  .card-details-right
  .card-details-item {
  font-size: 16px;
  margin-bottom: 1rem;
}

.author-bio-card-details
  .card-details-right
  .card-details-item
  h5 {
  font-weight: bold;
}

.listings-header {
  font-weight: bold;
}

.listings-header .listings-length {
  color: var(--textSecondary);
}

.outputs-row {
  margin: 0 0 1rem 0;
  overflow: auto;
  white-space: nowrap;
}

.output-card-link,
.output-card-link:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px #00000029;
  border-radius: 5px;
  width: 17%;
  min-height: 200px;
  min-width: 200px;
  color: var(--textPrimary);
  margin: 0 1rem 1rem 0rem;
  padding: 0.5rem;
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
}

.output-card-link .output-card .output-club-name {
  font-size: 12px;
  color: var(--textSecondary);
}

.output-card-link .output-card .card-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.output-card-link .output-card .output-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Media Queries */
@media only screen and (max-width: 991px) {
  .output-card-link {
    width: 30%;
  }
}

@media only screen and (max-width: 540px) {
  .authors-page {
    margin: 0;
  }

  .authors-page .authors-page-content .clubs-stats {
    display: block;
  }

  .authors-page
    .authors-page-content
    .clubs-stats
    .search-clubs {
    width: 100%;
  }

  .authors-cards .alphabet-list {
    justify-content: unset;
    border-radius: 10px;
    padding: 1rem;
  }

  .output-card-link {
    width: 45%;
    margin-left: 0;
  }
}
