.sidebar-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.sidebar-item__title {
  font-weight: bold;
}

.sidebar-item__action {
  color: #A8A8A8;
  cursor: pointer;
}
