.campaign-page {
  color: var(--textPrimary);
}

.campaign-page .col-md-4 {
  padding: 0;
}

.campaign-page.container {
  max-width: 100%;
  margin: 0;
}

.nav-campaign-date {
  color: var(--textSecondary);
}

.campaign-date-nav {
  color: var(--textPrimary);
  font-weight: bold;
  padding-left: 1rem;
}

.redirect-to-auth {
  margin-top: 8rem;
}

.campaign-main {
  padding-top: 8rem;
  padding-left: 5rem;
  font-size: 16px;
}

.display-article-title {
  font-weight: bolder;
  font-size: 30px;
  padding: 1rem 0;
}

.display-article-title-small {
  color: var(--textSecondary);
  padding-bottom: 0.5rem;
  white-space: pre-line;
}

.campaign-author {
  color: var(--primaryColor);
  font-size: 12px;
  padding-bottom: 0.5rem;
}

.campaign-stats {
  width: 20%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.campaign-stats .activity-stat-item {
  color: var(--textSecondary);
  font-size: 14px;
  display: flex;
  align-items: center;
}

.campaign-stats .activity-stat-item svg {
  width: 18px;
}

.campaign-stats .activity-stat-item .activity-stat-icon {
  padding-right: 1rem;
}

.campaign-stats
  .activity-stat-item
  .activity-stat-icon.handicon {
  /* transform: rotate(45deg); */
}

.campaign-content h5 {
  font-weight: bold;
  font-size: 16px;
}

.campaign-comments {
  padding: 6rem 1rem 1rem 1rem;
  background: #f7f4f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
}

.campaign-comments .comments-title {
  padding: 1rem 0;
  font-size: 20px;
  font-weight: bolder;
}

.write-comment {
  margin-bottom: 2rem;
}

.write-comment-author,
.comment-item-author {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}

.write-comment-author .comment-avatar,
.comment-item-author .comment-avatar {
  /* padding: 0 1rem 1rem 0; */
  margin-right: 1rem;
  width: 40px;
  height: 40px;
  background: #e8daca 0% 0% no-repeat padding-box;
  border-radius: 50%;
}

.write-comment-author .comment-avatar .member-initials,
.comment-item-author .comment-avatar .member-initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  letter-spacing: 0px;
  color: #4b515d;
}

.write-comment-author .comment-author-name,
.comment-item-author .comment-author-name {
  font-size: 14px;
  font-weight: bolder;
}

.comment-item-author
  .comment-author-name
  .comment-item-date {
  color: var(--textSecondary);
  font-weight: normal;
}

.write-comment textarea {
  width: 100%;
  border: none;
  outline: none;
  color: var(--textPrimary);
}

.write-comment textarea:focus {
  width: 100%;
  border: none;
}

.write-comment textarea::placeholder {
  color: var(--textSecondary);
}

.write-comment .post-buttons {
  justify-content: flex-end;
}

.write-comment .post-buttons .cancel-button {
  margin-right: 1rem;
}

.write-comment .post-buttons .cancel-button .btn {
  border: none;
  box-shadow: none;
  background-color: unset;
  width: 100%;
}

.write-comment .post-buttons .cancel-button .btn:hover {
  border: none;
  box-shadow: none;
  background-color: unset;
  color: var(--primaryColor) !important;
  text-decoration: underline;
}

.post-buttons .btn {
  width: 80px;
  font-size: 12px;
  border-radius: 5px;
}

.comment-item {
  border-bottom: 3px solid #e6e5e2;
  padding: 1rem;
}

.article-comment-card {
  background-color: transparent !important;
  box-shadow: none;
}

.campaign-content .article-header-image img {
  border-radius: 10px;
}

.share-button {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--textSecondary);
}

.share-button .share-button-label {
  margin-left: 1rem;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  .campaign-main {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .campaign-main {
    padding-top: 6rem;
    padding-left: 0rem;
    padding-right: 0rem;
    font-size: 14px;
  }

  .display-article-title {
    width: unset;
    font-weight: bolder;
    font-size: 20px;
    padding: 1rem 0;
  }

  .campaign-stats {
    width: 50%;
  }

  .campaign-stats .activity-stat-item {
    font-size: 12px;
  }

  .campaign-stats .activity-stat-item svg {
    width: 16px;
  }

  .campaign-content h5 {
    font-size: 14px;
  }

  .campaign-comments {
    padding-top: 1rem;
  }

  .comment-item {
    font-size: 14px;
  }

  .share-button {
    font-size: 12px;
    width: min-content;
  }

  .share-button .share-button-label {
    margin-left: 0;
  }
}
