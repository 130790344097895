.addMemberModal {
  width: 960px;
  height: 700px;
  margin: 19px 325px;
  border-radius: 10px;
  background: #f7f4f2 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 40px 60px;
  outline: none;
}

.addMemberModal .addParticipant h1 {
  color: #424242;
  font: normal normal 600 30px/42px Open Sans;
  opacity: 1;
}

.addMemberModal .addParticipant p {
  color: var(--unnamed-color-424242);
  text-align: left;
  font: normal normal normal 20px/42px Open Sans;
  letter-spacing: 0px;
  color: #424242;
  opacity: 1;
}

.addMemberModal .searchBar {
  width: 841px;
  height: 50px;
  background-color: #ffffff;
  border-radius: 10px;
  opacity: 1;
  border: none;
}

.addMemberModal .searchBar .autocompleteSearch {
  width: 100%;
  height: 50px !important;
  border: none;
}

.MuiInputBase-root {
  background-color: #ffffff;
  border-radius: 10px;
}

.searchedUsersTable {
  padding: 10px 0;
  margin: 50px 0;
  margin-bottom: 5px;
  height: 22rem;
}

.searchedUsersTableTitles {
  border-bottom: 2px solid #f2ebe5;
  padding-left: 5px;
}

.searchedUsersTableTitles h1 {
  font-size: 16px;
  font-weight: 600;
  color: #424242;
}

.searchedUsersAll {
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 5px;
}
.MuiAutocomplete-listbox {
  overflow-x: hidden !important;
}

.searchedUsersTable .searchedTableContent {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.alignItemsCenter {
  display: flex;
  justify-content: space-between;
}

.alignTextCenter h1 {
  margin-top: 20px;
  font-size: 15px;
}

.alignItemsCenter .removeUser {
  height: 10px !important;
  margin-top: 18px;
  font-size: 15px;
  color: #c6a177;
}

.alignItemsCenter .removeUser button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: #c6a177;
}

.searchedUsersTable .searchedTableContent select {
  border: none;
  background-color: #ffffff;
  padding: 15px 147px 15px 20px;
  font-size: 16px;
  border-radius: 10px;
  outline: none;
}

.buttonActions {
  margin-left: 150px;
}

.buttonActions button {
  margin: 14px 20px;
  padding: 10px 79px;
  border-radius: 10px;
  outline: none;
  font-size: 15px;
  border: 2px solid #f38f46;
}

.buttonActions .cancelButton {
  background-color: transparent;
  box-shadow: 0px 3px 5px #00000040;
  color: #f38f46;
  font-weight: 600;
}

.buttonActions .saveButton {
  background-color: #f38f46;
  box-shadow: 0px 3px 5px #00000040;
  color: #ffffff;
  font-weight: 600;
}

.MuiAutocomplete-inputRoot[class*='MuiInput-root']
  .MuiAutocomplete-input:first-child {
  padding: 0 !important;
  margin: 15px 20px !important;
}

@media screen and (min-width: 1824px) {
  .addMemberModal {
    width: 960px;
    height: 800px;
    margin: 100px auto;
    border-radius: 10px;
    background: #f7f4f2 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 52px 60px;
  }
}

@media (max-width: 1024px) {
  .addMemberModal {
    width: 900px;
    height: 600px;
    margin: 20px 60px;
    overflow: auto;
  }
  .addMemberModal .addParticipant h1 {
    font-size: 22px;
  }

  .addMemberModal .addParticipant p {
    font-size: 18px;
  }
}

@media (max-width: 850px) {
  .addMemberModal {
    width: 600px;
    height: 610px;
    margin: 10px 85px;
  }
  .selectedUsername {
    display: none;
  }
  .alignItemsCenter .removeUser span {
    display: none;
  }
  .alignItemsCenter .removeUser {
    margin-top: 15px;
    margin-left: 70px;
  }
  .alignItemsCenter .removeUser img {
    margin-left: 65px;
  }
  .buttonActions {
    margin-left: 20px;
    margin: 0;
  }

  .buttonActions button {
    margin: 8px;
    padding: 10px 79px;
    border-radius: 10px;
    outline: none;
    font-size: 15px;
    width: 100%;
    border: 2px solid #f38f46;
  }
}

@media (max-width: 568px) {
  .addMemberModal {
    width: 90% !important;
    margin: 30px auto !important;
    padding: 10px !important;
    height: 90% !important;
  }
  .searchedUsersTable {
    padding: 0;
    margin: 20px 0 0 0;
    height: 20rem;
  }
  .searchedUsersTable .searchedTableContent select {
    border: none;
    background-color: #ffffff;
    padding: 5px 40px 5px 10px;
    font-size: 16px;
    border-radius: 7px;
    outline: none;
  }
  .searchedUsersAll {
    height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 5px;
  }

  .alignItemsCenter .removeUser {
    margin-top: 5px;
    margin-left: 80px;
  }
  .alignTextCenter h1 {
    margin-top: 0;
    font-size: 15px;
  }
  .buttonActions {
    margin-left: 20px;
    margin: 0;
  }

  .buttonActions button {
    margin: 5px;
    padding: 6px 79px;
    border-radius: 10px;
    outline: none;
    font-size: 15px;
    width: 100%;
    border: 2px solid #f38f46;
  }

  .alignItemsCenter .removeUser img {
    margin-left: 0;
  }
}

@media (max-width: 540px) {
  .addMemberModal {
    width: 440px;
    height: 720px;
    margin: 30px 20px;
  }
}

@media (max-width: 375px) {
  .addMemberModal {
    width: 420px;
    height: 700px;
    margin: 70px 18px;
  }
}

@media (max-width: 320px) {
  .addMemberModal {
    width: 400px;
    height: 700px;
    margin: 40px 15px;
  }
  .alignItemsCenter .removeUser {
    margin-top: 5px;
    margin-left: 60px;
  }
  .alignItemsCenter .removeUser img {
    margin-left: 10px;
  }
}

@media (max-width: 280px) {
  .addMemberModal {
    width: 380px;
    height: 720px;
    margin: 70px 17px;
  }
}
