.resource-table {
  .w-10 {
    width: 10%;
  }
}

.name-cell {
  width: 20%;
}

.description-cell {
  width: 25%;
}

.id-cell {
  width: 2%;
}
