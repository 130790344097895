.contribution__card {
  margin: 8px;
  width: 320px;
  /* min-height: 420px; */
}

.contribution__card > img {
  height: 300px;
  object-fit: cover;
  cursor: pointer;
}

.output-card .details {
  display: flex;
  justify-content: space-between;
}

.output-card .details .details__main {
}

.output-card .details .details__main .h6 {
  color: black;
}
.output-card .details .details__main small {
  color: #a3a3a3;
}

.output-card .details .details__more {
  cursor: pointer;
}

.output-card .reactions {
  display: flex;
  justify-content: space-between;
}

.output-card .reactions .reactions__main {
  justify-content: space-between;
}

.output-card .reactions .reactions__main > span,
.output-card .reactions .reactions__sub > span {
  margin: 0 4px;
  align-items: center;
  color: #a3a3a3;
}

.output-card .reactions .reactions__main span:not(:first-child) {
  color: black;
}

.output-card span img {
  margin: 0 2px;
  cursor: pointer;
}
