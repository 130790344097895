/* Manage-Site */

.manage-site-page {
  color: var(--textPrimary);
  padding-top: 7rem;
}

.manage-site-page .col-md-4 {
  padding: 0;
}

.manage-site-page.container {
  max-width: 100%;
  margin: 0;
}

.manage-site-page .buttons-row {
  margin: 0 0 2rem 2rem;
}

.manage-sections {
  width: 80%;
  padding: 1rem 1rem 1rem 3rem;
  background: #f7f4f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
}

.manage-sections .sections-title {
  padding: 1rem 0;
  font-size: 20px;
  font-weight: bolder;
}

.manage-sections .sections-list {
  display: flex;
  flex-direction: column;
}

.manage-sections .sections-list a {
  color: var(--textPrimary);
  margin-bottom: 0.8rem;
  font-size: 16px;
}

.content-main {
  margin-top: -2rem;
  padding-left: 5rem;
  font-size: 16px;
}

.content-title {
  font-weight: bolder;
  font-size: 30px;
  padding: 1rem 0;
}

.content-sections {
  width: 90%;
}

.content-sections .section-card {
  background: #f7f4f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 1px;
  margin: 2rem 0;
  min-height: 200px;
}

.content-sections .section-card .input-group-prepend {
  box-shadow: 0px 1px 3px #00000029;
  background-color: transparent;
  border-radius: 10px 0px 0px 10px;
}

.content-sections
  .section-card
  .input-group-prepend
  .input-group-text {
  background: #e8daca 0% 0% no-repeat padding-box;
  border: none;
  min-width: 140px;
}

.content-sections .card-title {
  color: var(--textPrimary);
  font-weight: 600;
}

.content-sections .image-card .card-body {
  display: flex;
  justify-content: space-between;
}

.content-sections .image-card .card-body .card-details {
  width: 50%;
}

.content-sections
  .image-card
  .card-body
  .card-details
  button {
  position: absolute;
  bottom: 10%;
}

.content-sections .image-card .card-image-preview img {
  width: 300px;
}

.content-sections .helper-text {
  color: var(--textSecondary);
  font-size: 14px;
}

.content-sections input,
.content-sections input:focus,
.content-sections textarea,
.content-sections textarea:focus {
  color: var(--textPrimary);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border-radius: 10px;
  border: none;
  outline: none;
}

.content-sections textarea {
  width: 100%;
  padding: 1rem;
}

.content-sections #customFile {
  width: 10%;
  border-radius: 0 3px 3px 0;
  height: 100%;
}

.upload-doc-input {
  height: 38px;
  display: flex;
  align-items: center;
}

.upload-doc-input p,
.upload-doc-input label {
  margin: 0 !important;
}

.section-card .post-buttons {
  justify-content: flex-end;
}

.section-card .post-buttons .cancel-button {
  margin-right: 1rem;
}

.section-card .post-buttons .cancel-button .btn {
  border: none;
  box-shadow: none;
  background-color: unset;
  width: 100%;
}

.section-card .post-buttons .cancel-button .btn:hover {
  border: none;
  box-shadow: none;
  background-color: unset;
  color: var(--primaryColor) !important;
  text-decoration: underline;
}

/* Manage-Clubs */

.manage-clubs-page {
  padding-top: 8rem;
  margin: 0 2rem;
}

.added-div {
  height: 500px;
}

.manage-clubs-content {
  color: var(--textPrimary);
}

.manage-clubs-content .clubs-details .clubs-title {
  margin: 2rem 0 2rem 0;
  color: var(--textPrimary);
}

.manage-clubs-content .clubs-details .clubs-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-clubs-content
  .clubs-details
  .clubs-stats
  .search-clubs {
  width: 375px;
}

.manage-clubs-content
  .clubs-details
  .clubs-stats
  .search-clubs
  input,
.manage-clubs-content
  .clubs-details
  .clubs-stats
  .search-clubs
  button {
  background-color: #ddd5cc !important;
}

.manage-clubs-content .clubs-details {
  margin-bottom: 1rem;
}

.manage-clubs-content .clubs-table thead tr {
  background: #f7f4f2 0% 0% no-repeat padding-box;
  border-radius: 3px;
}

.manage-clubs-content .clubs-table .club-name {
  color: var(--textPrimary);
}

.manage-clubs-content .club-avatar {
  width: 40px;
  height: 40px;
}

.manage-clubs-content .club-role {
  padding: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage-clubs-content .club-role div {
  background: var(--unnamed-color-f38f46) 0% 0% no-repeat
    padding-box;
  background: var(--primaryColor) 0% 0% no-repeat
    padding-box;
  border-radius: 13px;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  padding: 0.3rem 1rem;
}

.manage-clubs-content svg {
  color: var(--textSecondary);
}

@media (max-width: 590px) {
  .content-sections
    .image-card
    .card-body
    .card-details
    button {
    bottom: 0;
  }
  #logo img {
    width: 150px;
    height: 150px;
  }
  .content-sections #customFile {
    width: 35%;
    border-radius: 0 3px 3px 0;
    height: 100%;
  }

  .upload-doc-input {
    height: 38px;
    display: flex;
    align-items: center;
  }

  .upload-doc-input p,
  .upload-doc-input label {
    margin: 0 !important;
    font-size: 12px !important;
  }
}
