.actions-bar {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 4px #00000029;
  background-color: #FFFFFF;
  padding: 1rem;
  width: 100%;
}

.actions-bar__title {
  font-weight: bold;
}

.actions-bar__title__files-count {
  padding-left: 0.5rem;
  color: var(--textSecondary);
}

.actions-bar__actions {
  display: flex;
  justify-content: space-around;
}

.actions-bar__actions__action-item {
  margin: 0 1rem;
  cursor: pointer;
}

@media (max-width: 992px) {
  .actions-bar {
    padding: 0.5rem;
  }

  /* .actions-bar__title {
    display: none;
  } */

  .actions-bar__actions__action-item {
    font-size: 13px;
    margin: 0 0.4rem;
  }

  .actions-bar__actions__action-item__icon {
    width: 13px;
  }
}
