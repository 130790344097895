.create-article-header {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.create-article-header .create-article-title .article-title {
  font-size: 35px;
  font-weight: bold;
}

/* .create-article-header .create-article-title .article-helper {
  
} */

.article-card {
  padding: 2rem;
}

.club-select-row {
  display: flex;
  margin-top: 1rem;
  align-items: center;
}

.club-select-row label {
  min-width: fit-content;
  margin: 0;
  margin-right: 1rem;
}

.create-article-header .edit-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.edit-button > span {
  margin-right: 1rem;
  cursor: pointer;
}

.article-card .create-card-header {
  display: flex;
  justify-content: space-between;
}

.article-card .card-header-title {
  margin-bottom: 1rem;
}

.article-card .card-header-title h5 {
  font-weight: bold;
}

.article-card .card-header-title .asterisk {
  color: var(--primaryColor);
}

.article-card .form-icon {
  padding: 0;
}

.article-card .col-md-12 {
  padding: 0;
}
.article-card .col-md-12.boolean {
  display: flex;
  padding: 0;
}
.col-md-12.boolean > div > label, .col-md-12.boolean .col-md-6 {
  padding: 0 10px 0 0;
}

.article-card > .col-md-12.boolean .col-md-6 {
  padding: 0;
}
.article-card input,
.article-card textarea,
.article-card label {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  border: none;
  width: 100%;
  outline: none;
}

.article-card input {
  min-height: 56px;
  box-shadow: 0px 3px 5px var(--backgroundColor);
}

.article-card input[type="file"] {
  display: none;
}

.article-card .description-textarea textarea {
  padding-top: 0.5rem;
  box-shadow: 0px 3px 5px var(--backgroundColor);
}

.article-card .main-textarea textarea {
  padding: 0;
}

.article-card .create-card-image-preview {
  min-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border-radius: 10px;
}

/* .article-card .actions-buttons,
.article-card .action-button {
  width: 100%;
  height: 100%;
} */


@media only screen and (max-width: 540px) {
  .create-article-header {
    display: block;
    margin-top: 1rem;
    margin: 1rem;
  }

  .create-article-header .create-article-title {
    margin-bottom: 1rem;
  }

  .create-article-header .create-article-title .article-title {
    font-size: 30px;
  }

  .article-card {
    padding: 1rem;
  }
}
