.output-profile-page {
  margin: 100px 0;
  padding: 50px;
  //profile-page-header
  .profile-page-header {
    display: flex;
    align-items: center;
    button {
      background: transparent;
      border: none;
      outline: none;
    }
    .back-icon {
      color: #a8a8a8;
    }
    h1 {
      margin: auto 20px;
      font-size: 28px;
      font-weight: 600;
    }
  }

  //profile-card
  .profile-card {
    background: #ffffff;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 10px;
    margin: 30px 0;
    display: flex;
    width: 100%;
    padding: 50px;
    .profile-information {
      img {
        width: 250px;
        height: 250px;
        border-radius: 50%;
      }
      p {
        font-size: 16px;
        margin: 20px 0;
        color: #a3a3a3;
      }
    }
    .output-information {
      padding-left: 50px;
      h3 {
        font-size: 16px;
        color: #424242;
        font-weight: 600;
      }
      p {
        font-size: 16px;
        color: #4b515d;
      }
      .indicators-div {
        display: flex;
        justify-content: space-between;
        width: 50%;
        font-size: 18px;
        .active,
        .gender,
        .location {
          display: flex;
          align-items: center;
          .active-icon {
            background-color: #10a728;
            width: 10px;
            height: 10px;
            border-radius: 50%;
          }
          p {
            margin: auto 10px;
          }
          .icon {
            color: #a3a3a3;
          }
        }
      }
      .profile-description {
        padding: 50px 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .output-profile-page {
    padding: 20px 0;
  }
  .output-profile-page .profile-card {
    display: block;
    padding: 30px;
  }
  .output-profile-page .profile-card .output-information {
    padding-left: 0;
  }
  .output-profile-page .profile-page-header h1 {
    font-size: 25px;
  }
}
