.contribution-comment-card {
  position: relative;
  z-index: 2;
  width: 337px;
  background: #fff;
  box-shadow: 0px 6px 12px #00000040;
  border-radius: 3px;
  height: 50%;
  .cancel-icon {
    height: 26px;
    padding: 0;
    z-index: 2;
    margin-right: 5px;
    margin-top: 5px;
    float: right;
    svg {
      padding: 0;
      margin: 0 !important;
    }
  }
}
.contribution-comment {
  width: 100%;
  .comments-section {
    max-height: 245px;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    scroll-snap-type: proximity;
    div {
      div {
        scroll-snap-align: end;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px #ffffff;
    border-radius: 10px;
  }
  .top-content {
    padding: 20px;
    display: flex;
    img {
      width: 41px;
      height: 41px;
      border-radius: 50%;
      object-fit: contain;
      background: #f7f4f2;
    }
    .contribution-name {
      width: 90%;
      padding-left: 10px;
      h3 {
        font-size: 16px;
        font-weight: 600;
        color: #424242;
        margin: 0;
      }
      p {
        color: #a3a3a3;
        font-size: 13px;
        margin: 0;
      }
    }
    svg {
      align-self: unset;
    }
  }
  .comment-content {
    border-bottom: 1.5px solid #0000002d;
    p {
      color: #424242d9;
      font-size: 16px;
      padding: 0 30px 0 70px;
    }
  }
  .comment-input-section {
    textarea {
      width: 100%;
      padding-left: 20px;
      position: relative;
      top: 10px;
      border: none;
      outline: none;
      overflow-wrap: break-word !important;
      word-wrap: break-word !important;
    }
  }
  .reply-button {
    display: flex;
    justify-content: flex-end;

    button {
      margin: 10px 10px 20px 0;
      background: #f38f46;
      color: #ffffff;
      font-size: 13px;
      padding: 5px 20px;
      border-radius: 5px;
      border: none;
      outline: none;
    }
  }
}

@media (max-width: 982px) {
  .contribute-section .right-div .inside-content img {
    margin-top: 0;
    width: 41px;
    height: 41px;
  }
  .contribution-comment .comment-content p {
    padding: 0 30px 0 70px;
  }
}

@media (max-width: 500px) {
  .contribution-comment-card {
    right: 141px;
    width: 100% !important;
  }
}
