.club-activities {
  margin: 0 0 50px;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 40px;
  box-shadow: 0px 2px 4px #00000029;
}

.club-activities .header-title {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  padding-top: 10px;
  border-bottom: 1px solid #cccccc;
}
.club-activities .header-title > h1 {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.club-activities .header-title > .viewAllButton {
  width: 90%;
  text-align: end;
}

.club-activities .header-title > .viewAllButton > button {
  color: #f38f46;
  background: none;
  border: none;
}

.club-activities .body .activities-details {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

@media (max-width: 599px) {
  .club-activities {
    margin: 0;
    background: transparent;
    border-radius: 10px;
    padding: 10px 0;
    box-shadow: none;
  }

  .club-activities .header-title {
    margin: 0;
    background: transparent;
    padding: 5px 0;
    box-shadow: none;
  }

  .club-activities .header-title > h1 {
    font-size: 13px;
    text-align: center;
    font-weight: 600;
  }

  .participantItem {
    margin: 5px;
    text-align: center;
  }
  .participantItem .avatar {
    width: 65px;
    height: 65px;
  }

  .club-activities .body .activities-details {
    overflow: hidden;
  }
}
