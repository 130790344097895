.p-x-50 {
  padding: 0 50px;
}
.min-height-500 {
  min-height: 500px;
}
.box-shadow-none {
  box-shadow: none;
}
.output-modal {
  overflow: scroll;
  padding-bottom: 50px;
  .output-modal-content {
    background-color: white;
    width: 800px;
    min-height: 802px;
    margin: auto;
    margin-top: 50px;
    border-radius: 10px;
    outline: none !important;
    .output-modal-title {
      padding-top: 30px;
      h1 {
        font-size: 25px;
        font-weight: 600;
      }
      p {
        padding: 0;
        font-size: 16px;
      }
    }
    .output-step-indicator {
      display: flex;
      align-items: center;
      padding-top: 10px;
      hr {
        width: 15%;
        border: 0.5px solid #e6e5e2 !important;
        margin: 0;
      }

      .first-line,
      .fourth-line {
        width: 5%;
      }

      .second-line {
        width: 20%;
      }

      .third-line {
        width: 23%;
      }

      .output-basic-information-indicator,
      .output-sources-indicator,
      .output-settings-indicator {
        display: flex;
        font-size: 13px;
        color: #999999;
        height: fit-content;
        align-items: center;
        cursor: pointer;
        span {
          background-color: transparent;
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          border: 1px solid #cccccc;
          color: #999999;
          border-radius: 50%;
          text-align: center;
          padding-top: 7px;
          font-size: 16px;
        }
        p {
          margin: 0 5px;
        }
      }
      .active-step {
        span {
          background-color: #f38f46;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-align: center;
          padding-top: 7px;
          color: #ffffff;
          font-size: 16px;
          border: none;
        }
      }
    }

    .border-separator {
      margin-top: 50px;
      width: 99%;
      border: 0.5px solid #e6e5e2;
    }
    .create-outputs-buttons {
      padding: 5px 50px;
      float: right;
      .cancel-button {
        width: 170px;
        height: 40px;
        background-color: transparent;
        border: none;
        color: #e0995d;
      }
      .next-button {
        width: 170px;
        height: 40px;
        background-color: #f38f46;
        border: none;
        color: #ffffff;
        border-radius: 10px;
      }
      .disabled {
        background-color: rgba(255, 149, 74, 0.555);
      }
    }
  }
  .create-output-sources {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
