.questionnaireForm {
  margin-top: 3em;
  padding-top: 2em;
}

.question-container {
    border-radius: 1px;
    padding: 1rem;
    min-height: 150px;
    width: 95%;
    text-align: start;
  }
  
  .question-container h5 {
    font-size: 16px;
    font-weight: normal !important;
  }
  
  .questionnaire-section {
    width: 100%;
  }
  
  .questionnaire-section .section-card {
    background: #ffffff;
    box-shadow: none;
    box-shadow: 0px 3px 5px #0000000d;
    border-radius: 10px;
    margin: 1rem 0;
    min-height: 150px;
    width: 100%;
    border: none;
  }
  
  .questionnaire-section .section-card .input-group-prepend {
    box-shadow: 0px 1px 3px #00000029;
    background-color: transparent;
  }
  
  .questionnaire-section
    .section-card
    .input-group-prepend
    .input-group-text {
    background: #e8daca 0% 0% no-repeat padding-box;
    border: none;
    min-width: 140px;
  }
  
  .questionnaire-section .section-card .card-body {
    padding: 20px !important;
  }
  
  .questionnaire-section .card-details .title-row {
    display: flex;
    justify-content: space-between;
  }
  
  .questionnaire-section .card-details .card-title {
    color: var(--textPrimary);
    font-weight: 600;
    font-size: 16px;
  }
  
  .questionnaire-section .card-details .character-counter {
    color: var(--textSecondary);
    font-style: italic;
    font-size: 16px;
  }
  
  .asterisk {
    color: var(--primaryColor);
  }
  
  .questionnaire-section .image-card .card-body {
    display: flex;
    justify-content: space-between;
  }
  
  .questionnaire-section
    .image-card
    .card-body
    .card-details {
    width: 70%;
  }
  
  .questionnaire-section
    .image-card
    .card-body
    .card-details
    button {
    position: absolute;
    bottom: 10%;
  }
  
  .questionnaire-section .image-card .card-image-preview {
    width: 165px;
    padding: 0.5rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px var(--backgroundColor);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .questionnaire-section .image-card .card-image-preview img {
    width: 100%;
  }
  
  .questionnaire-section .helper-text {
    color: var(--textSecondary);
    font-size: 14px;
  }
  
  .questionnaire-section input,
  .questionnaire-section select,
  .questionnaire-section input:focus,
  .questionnaire-section textarea,
  .questionnaire-section textarea:focus {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    outline: none;
    box-shadow: none;
    border: 1px solid #a3a3a3;
  }
  
  .card-details .form-group > input:focus {
    border: #a3a3a3 1px solid !important;
    box-shadow: none;
  }
  
  .questionnaire-section select {
    height: 56px;
  }
  
  .questionnaire-section textarea {
    width: 100%;
    padding: 1rem;
  }
  
  .questionnaire-section #customFile {
    width: 100%;
    border-radius: 0 3px 3px 0;
  }
  
  .upload-file-div {
    border: 1px dashed #f38f46;
    border-radius: 10px;
    background: #f7f4f2 0% 0% no-repeat padding-box;
    display: flex;
    align-content: space-between;
    height: 50px;
    width: 100%;
  }
  
  .upload-file-div div {
    margin: auto 0;
    height: 35px;
    width: 100%;
  }
  
  .upload-file-div > .custom-input {
    width: 45px;
  }
  
  .image-card .custom-file-inputz {
    visibility: hidden;
  }
  
  .image-card .custom-file-inputz::before {
    background-image: url('../../assets/icons/upload-path.svg');
    background-repeat: no-repeat;
    background-size: 45px 35px;
    display: inline-block !important;
    visibility: visible;
    font-size: 15px;
    width: 50px !important;
    height: 35px !important;
    z-index: 2;
    content: '';
    cursor: pointer;
  }
  
  .upload-file-div .upload-custom-file > label {
    margin-left: 10px;
    margin-top: 6px;
    cursor: pointer;
  }
  
  .sortable-item {
    border-radius: 10px;
    background: #ffffff;
    border: 0.5px solid #a8a8a8;
    box-shadow: 0px 3px 5px #00000040;
    padding: 0.5rem;
    margin: 0.5rem 0;
    cursor: pointer;
  }

  .foot-notes {
    padding: 1em;
  }

  .foot-notes h6 {
    font-size: 1em;
  }
  
  @media (max-width: 992px) {
    .question-container {
      margin: 0;
      width: 100%;
    }
  
    .upload-file-div > .custom-input {
      width: 60px;
    }
    .upload-file-div .upload-custom-file > label {
      margin-left: 10px;
      font-size: 13px;
    }
  }