.output-settings {
  .color-grayish {
    color: #a3a3a3;
  }
  .pr-10 {
    padding-right: 10px;
  }
  .settings-accordion {
    margin: 20px 0;
    .box-shadow-none {
      box-shadow: none;
    }
    .accordion-summary {
      .output-settings-activity {
        font-size: 15px;
      }
      .output-settings-action {
        margin-left: 150px;
        font-size: 15px;
      }
    }
    .form-group-row {
      width: 100%;
      .source-settings-details {
        display: flex;
        width: 90%;
        border: 1px solid #cccccc;
        height: 50px;
        border-radius: 7px;
        .setting-label {
          background-color: #f8f5f2;
          border-radius: 7px 0 0 7px;
          height: 100%;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          h1 {
            font-size: 15px;
            margin: 0;
          }
        }
        .select-values {
          width: 100%;
          border: none !important;
          .MuiOutlinedInput-root {
            padding: unset;
            height: 100%;
            border-radius: 0 7px 7px 0 !important;
          }
          .MuiOutlinedInput-notchedOutline {
            height: 100% !important;
            border: none;
          }
          .MuiFormControl-fullWidth {
            height: 100% !important;
          }
        }
        span {
          width: 80%;
          border-radius: 10px;
          height: 98%;
          border: none;
          outline: none;
          input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
          }
        }
        ul.react-autocomplete-input {
          margin-top: 27px;
          overflow-y: scroll;
          max-height: 50vh;
        }
        ::-webkit-scrollbar {
          width: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background: #cccccc;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 20px #ffffff;
          border-radius: 10px;
        }
        ul.react-autocomplete-input > li.active {
          background: #f38f46;
        }
      }
    }
  }
}
