.feed-page .row {
  margin-left: unset;
  margin-right: unset;
}

.feed-nav {
  width: 20%;
  height: calc(110vh);
  position: sticky;
  top: 6.2rem;
  margin-top: -3.8rem;
}

.feed-nav .container-fluid {
  background-color: #ffffff;
  /* color: #FFFFFF; */
  padding: 0rem 0rem;
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 3px;
  /* padding: 30px 0 !important; */
}

.css-sox5kk-MuiBackdrop-root {
  z-index: -1;
}

.feed-nav .nav-link {
  color: unset;
  font-size: 14px;
  cursor: pointer;
}

.feed-filter-mobile .nav-link {
  color: unset;
  cursor: pointer;
}

.feed-nav .nav-section {
  margin-bottom: 2rem;
}

.nav-section {
  padding: 30px 0 !important;
}

.feed-nav .bottom-section {
  margin-left: 1rem;
}

.feed-nav .side-nav-item {
  display: flex;
  align-items: center;
}

.feed-nav .nav-section .side-nav-item__active {
  color: var(--primaryColor);
  padding: 0.3rem 2rem;
  border-left: 5px solid var(--primaryColor);
  font-weight: 600;
}

.feed-nav .side-nav-item img {
  width: 20px;
  margin-right: 0.5rem;
}

.feed-nav .bottom-section {
  width: 90%;
}

.feed-nav .bottom-section .side-nav-footer {
  /* color: var(--textSecondary); */
  font-size: 12px;
}

.feed-nav
  .bottom-section
  .side-nav-footer
  .side-nav-footer-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 1rem;
}

.feed-nav
  .bottom-section
  .side-nav-footer
  .side-nav-footer-link {
  color: unset;
  margin: 0.5rem;
}

.feed-nav
  .bottom-section
  .side-nav-footer
  .side-nav-footer-link
  .side-nav-copyright {
  color: var(--textSecondary);
}

.feed-main {
  width: calc(80% - 0.5rem);
  margin-left: 0.5rem;
}

.feed-card {
  min-height: 300px;
  width: 100%;
  color: var(--textPrimary);
}

.feed-card .feed-card-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0.5rem;
  position: relative;
}

.feed-card .feed-card-header .filter-icon {
  cursor: pointer;
}

.feed-filter-mobile {
  display: none;
}

.feed-card .feed-card-header .dropdown-menu-right {
  z-index: 1;
  width: calc(100vw * 0.85);
  max-width: 320px;
  min-height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border: 0.5px solid #f0f0f0;
  border-radius: 10px;
  margin-top: 0.5rem;
  padding: 0;
}

.feed-card .feed-card-header .dropdown-menu-right img {
  font-size: 18px;
  width: 18px;
  margin-right: 1.5rem;
}

.feed-card .feed-card-header .dropdown-menu-right > a {
  font-size: 14px;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  color: var(--textPrimary) !important;
}

.feed-card .feed-card-header .dropdown-menu-right > a:hover,
.feed-card
  .feed-card-header
  .dropdown-menu-right
  > a:focus {
  background-color: var(--backgroundColor);
}

.feed-card .feed-card-header .dropdown-toggle::after {
  display: none;
}

.activity-content-row.article-row {
  display: flex;
}

.activity-content-row.article-row .activity-content {
  width: 77%;
  border-right: 2px solid #e6e5e2;
  padding-right: 1rem;
}

.activity-content .article-title {
  font-weight: bold;
  margin-bottom: 1rem;
}

.activity-content-row.article-row .article-bottom-row {
  color: var(--textSecondary);
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.activity-content-row.article-row
  .article-bottom-row
  .article-author-row {
  display: flex;
  justify-content: space-between;
}

.activity-content-row.article-row
  .article-bottom-row
  .article-author-row
  .article-author-name {
  padding-right: 1.5rem;
  border-right: 1px solid var(--textSecondary);
}

.activity-content-row.article-row
  .article-bottom-row
  .article-author-row
  .article-club-name {
  padding-left: 1.5rem;
}

.activity-content-row.article-row
  .article-bottom-row
  .article-author-row
  .article-club-name
  a {
  color: var(--textSecondary);
}

.activity-content-row.article-row .activity-stats {
  padding-left: 1rem;
}

.activity-content-row.article-row
  .activity-stats
  .activity-stat-item {
  margin-right: 0;
  padding: 0.4rem 0;
}

.activity-card .article-banner {
  margin: -1rem -1rem 1rem -1rem;
}

.activity-card .article-banner img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
}

.dropup .dropdown-toggle::after {
  display: none;
}

.article-description {
  white-space: pre-line;
}

.dropup .dropdown-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border: 0.1px solid var(--primaryColor);
  opacity: 1;
  padding: 0.5rem;
}

.reactions-icons .activity-stat-item {
  margin-right: 2rem !important;
}

.reactions-icons .activity-stat-item .activity-stat-icon {
  padding-right: 0.4rem !important;
}

.reactions-modal .nav-item {
  padding-left: 1.5rem;
}

.reactions-modal .nav-item a {
  color: var(--textSecondary);
}

.reactions-modal .nav-link.active {
  color: var(--primaryColor);
  border-bottom: 3px solid #ffb47e;
}

.reaction-number {
  padding-left: 0.5rem;
}

.reactions-modal .tab-pane .d-flex {
  min-height: 175px;
}

@media only screen and (max-width: 768px) {
  .feed-nav {
    display: none;
  }

  .feed-main {
    width: 100%;
  }

  .feed-main .container-fluid {
    padding: 0;
  }

  .feed-filter-mobile {
    display: block;
    margin-bottom: 2rem;
  }
}

@media (max-width: 992px) {
  .feed-main {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 540px) {
  .feed-card {
    padding: 0.5rem;
    margin-top: -1rem;
  }

  .activity-content-row.article-row {
    display: block;
  }

  .activity-content-row.article-row .activity-content {
    width: 100%;
    border-right: unset;
    border-bottom: 2px solid #e6e5e2;
    padding-right: unset;
    padding-bottom: 1rem;
  }

  .activity-stats {
    padding-left: 0 !important;
  }

  .activity-stats .d-flex {
    flex-direction: row !important;
  }

  .activity-content-row.article-row .article-bottom-row {
    flex-direction: column-reverse;
    margin-top: 0;
  }

  .activity-content-row.article-row
    .article-bottom-row
    .article-see-more {
    margin-bottom: 1rem;
  }

  /* .activity-content-row.article-row .article-bottom-row .article-author-row .article-author-name {
    padding-right: 0.5rem;
  }

  .activity-content-row.article-row .article-bottom-row .article-author-row .article-club-name {
    padding-left: 0.5rem;
  } */

  .activity-content-row
    .activity-stats.justify-content-between {
    flex-direction: column;
    margin-top: -1rem;
  }

  .activity-content-row
    .activity-stats.justify-content-between
    a {
    margin-top: 1rem;
  }
}
