.allContributions {
  .contribution {
    background-color: #ffffff;
    box-shadow: 0px 2px 4px #0000000d;
    padding: 20px;
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .texts {
      h5 {
        font-size: 18px;
        margin: 0 0 5px;
        font-weight: 600;
      }
      p.date {
        font-size: 16px;
        margin: 0;
      }
      p.status {
        display: inline-block;
        font-size: 14px;
        padding: 8px 16px;
        border-radius: 100px;
        color: #ffffff;
        margin: 14px 0 0;
      }
      p.APPROVED {
        background-color: #10a728;
      }
      p.REJECTED {
        background-color: #f40505;
      }
      p.PENDING_REVIEW {
        background-color: #e6b100;
      }
    }
    .comment-toggle-icon {
      width: 5%;
      margin-top: 7rem;
      position: absolute;
      .comment-icon {
        width: 90%;
        cursor: pointer;
        img {
          margin: 0 !important;
        }
        .comment-number {
          margin-left: 2px;
          font-size: 14px;
          color: #979797;
        }
      }
      .toggledDiv {
        height: 100px;
        border: 1px solid red;
      }
    }
    svg {
      fill: #cccccc;
      font-size: 25px;
      cursor: pointer;
      margin-top: 22px;
    }
  }
  @media (min-width: 600px) {
    .contribution-card .flex-content .comment-toggle-icon {
      width: 90% !important;
    }
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(240px, auto)
    );
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    .contribution {
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      .texts {
        h5 {
          font-size: 16px;
        }
        p.date {
          font-size: 13px;
        }
        p.status {
          font-size: 13px;
        }
      }
    }
    svg {
      align-self: flex-end;
    }
  }
}

.ResourceCardIntro {
  font-size: 13px;
  line-height: 22px;
  color: #424242;
  margin: 0 0 20px;
}
