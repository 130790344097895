.GlossaryCard{
    margin: 30px 0;
    .glossary-header {
        display: flex;
        justify-content: space-between;
        h1 {
          font-size: 16px;
          color: #424242;
          font-weight: 600;
        }
        span {
            display: flex;
            cursor: pointer;
            p.show {
              color: #f38f46;
              margin-left: 10px;
            }
        }
    }
    .glossary-content {
        background: #ffffff;
        width: 100%;
        padding: 25px;
        box-shadow: 0px 2px 4px #00000029;
        border-radius: 10px;
        h3{
            font-size: 16px;
            color: #424242;
            font-weight: 600;
        }
        input {
            border: 1px solid #cccccc;
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            outline: none;
            color: #424242;
            font-size: 16px;
            margin-bottom: 30px;
        }
        textarea {
            border: 1px solid #cccccc;
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            outline: none;
            color: #424242;
            font-size: 16px;
            min-height: 200px;
        }
        .button-block{
          display: flex;
          justify-content: flex-end;
          margin-top: 16px;
          button {
            background-color: #f38f46;
            color: #ffffff;
            border: none;
            border-radius: 8px;
            padding: 8px 16px;
          }
        }
        @media (max-width: 768px) {
            padding: 10px;
        }
    }
}

.add-new-section{
  cursor: pointer;
  display: flex;
  p {
    margin-left: 6px;
  }
}