@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import "./variables.css";

* {
  font-family: "Open Sans", sans-serif;
}

body {
  color: var(--textPrimary);
  background: var(--backgroundColor);
  margin: 0;
  padding: 0;
}

#mobile-bar {
  display: none;
}

.btn {
  border-radius: 10px;
}

.white-space-pre-line {
  white-space: pre-line;
}

.text-dark {
  color: #000;
}

.cursor-pointer {
  cursor: pointer;
}

#intro {
  background-repeat: no-repeat;
  background-position: left, 10px;
  background-size: cover;
  height: 100vh;
  color: #ffffff;
  font: Semibold 20px/40px Open Sans;
}

#intro #logo {
  margin: 1rem;
}

#intro-text {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  margin: -1rem;
}

#intro-text .intro-hr {
  height: 100%;
}

#intro-text .slogan-text {
  max-width: 300px;
  margin-bottom: 1.2rem;
}

#form-block {
  /* height: 100vh; */
  background-image: url("../assets/bg-right.svg"),
    url("../assets/right-end-circles.svg"),
    url("../assets/top-right-circles.svg");
  background-repeat: no-repeat;
  background-position: right, bottom right -125px, top right;
  background-size: contain, 250px, 350px;
}

.header-title {
  font-size: 28px;
  font-weight: 900;
  color: #424242;
}

.login-form,
.signup-form {
  height: 100%;
}

.intro-divider {
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 4rem;
}

#logo img {
  width: 185px;
  height: 185px;
}

#search-btn {
  background-color: #dedcd8;
  border: 1.5px solid #a8a8a8;
  border-left: 0;
}

#clear-search-btn {
  background-color: #ddd5cc;
  color: #707070;
  border: none;
}

#clear-search-btn:hover {
  background-color: #ddd5cc;
  color: #707070;
}

form {
  color: #707070;
}

/* .login-form {
    margin-top: 15%;
} */

.auth-comp {
  padding-left: 2rem;
  padding-right: 2rem;
}

.form-group > input,
.input-group > input,
.form-group > input:focus,
.input-group > input:focus {
  background-color: transparent;
  border: 1.5px solid #a8a8a8;
  border-radius: 10px;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  height: 56px;
}

.input-group > input,
.input-group > input:focus {
  background-color: #dedcd8;
  height: 48px;
  border-right: 0;
}

.btn {
  box-shadow: 0px 3px 5px var(--backgroundColor);
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1.5px solid #a8a8a8;
  border-radius: 100%;
  background: var(--backgroundColor);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #a8a8a8;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* [type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  position: absolute;
  left: -9999px;
} */

/* [type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1.5px solid #a8a8a8;
  background: var(--backgroundColor);
}

[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
  content: "✔";
  position: absolute;
  left: 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
} */

.form-icon img {
  position: absolute;
  left: 1rem;
  top: 0.9rem;
  padding: 9px 8px;
}

.forgot-link,
.back-to-sign {
  cursor: pointer;
  color: #e69355;
}

.forgot-link:hover,
.back-to-sign:hover {
  text-decoration: underline;
}

.modal {
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
}

.modal-card {
  background: #ffffff;
  box-shadow: 0px 3px 10px #00000029;
}

.logo-modal {
  width: 150px;
}

.forgot-modal-title {
  color: var(--textPrimary);
  font-size: 30px;
  font-weight: 900;
}

.forgot-modal-text {
  color: var(--textPrimary);
  font-size: 20px;
}

.submit-btn {
  background-color: var(--primaryColor);
  color: #ffffff;
  width: 10rem;
  font-size: 14px;
  border: 1.5px solid var(--primaryColor);
  box-shadow: 0px 3px 5px #00000040;
}
.submit-btn:hover,
.submit-btn:focus {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  border: 1.5px solid var(--primaryColor);
}
.btn-reverse {
  background-color: var(--backgroundColor);
  color: var(--primaryColor);
  width: 10rem;
  font-size: 14px;
  border: 1.5px solid var(--primaryColor);
  box-shadow: 0px 3px 5px #00000040;
}
.btn-reverse:hover,
.btn-reverse:focus {
  background-color: var(--primaryColor);
  color: #ffffff;
  border: 1.5px solid var(--primaryColor);
}
.btn[disabled] {
  cursor: not-allowed;
}

.fallback-block {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.select-dropdown {
  background: transparent;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border-radius: 10px;
  padding: 0.2rem 0 0.2rem 1rem;
  border: 1.5px solid #a8a8a8;
  margin-bottom: 1rem;
}

.select-dropdown .select-header {
  font-size: 12px;
  color: var(--textSecondary);
}

.select-dropdown .select-input {
  display: flex;
  font-size: 16px;
  align-items: center;
}

.select-dropdown .select-input select,
.select-dropdown .select-input select:focus {
  border: none;
  padding: 0;
  margin-left: -0.2rem;
  margin-top: -0.4rem;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: transparent;
}

/* ======== EDITOR STYLES ========= */
.wrapper-class {
  padding: 1rem;
  /* border: 1px solid #ccc; */
}
.editor-class {
  /* background-color: lightgray; */
  min-height: 200px;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}
/* ======== EDITOR STYLES ========= */

/* Media Queries */
@media only screen and (max-width: 1024px) {
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    top: 1px;
  }
}
@media only screen and (max-width: 992px) {
  #mobile-bar {
    width: 100%;
    display: block;
    height: 2rem;
    padding: 0 10px;
    background-color: #e8e7e3;
    box-shadow: 0px 3px 10px #00000029;
  }

  #intro {
    background-image: url("../assets/mobile-bg.svg");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    height: 100%;
    color: #ffffff;
    margin: 0;
  }

  #logo {
    height: 20rem;
    display: flex;
    justify-content: center;
  }

  #logo img {
    position: absolute;
    top: 10rem;
  }

  #search-bar,
  #back-homepage,
  #intro-text {
    display: none;
  }

  .auth-comp {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  .forgot-modal-title {
    font-size: 20px;
    font-weight: 900;
  }

  .forgot-modal-text {
    font-size: 14px;
  }

  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    top: 1px;
  }
  .club-page {
    box-shadow: 0;
  }

  .submit-btn {
    margin-bottom: 10px;
  }

  .endSaveButtons .submit-btn {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 412px) {
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    top: 1px;
  }
}
@media only screen and (max-width: 376px) {
  .gender-input {
    font-size: 0.75rem;
  }
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    top: 1px;
  }
}
