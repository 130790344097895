.profile-link {
  box-shadow: 0px 3px 5px #00000029;
  .action-details-section {
    padding: 20px;
    display: flex;
    width: 100%;
    .action-details-details {
      width: 80%;
      .details-title {
        h3 {
          font-size: 16px;
          font-weight: 600;
        }
      }
      .details-info {
        margin: 20px 0;
        .contribution-date {
          p {
            margin: 0;
            color: #a3a2a0;
          }
        }
      }
    }

    .action-details-status {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        width: 117px;
        border-radius: 13px;
        color: #ffffff;
        border: none;
        outline: none;
        font-size: 14px;
        height: 28px;
      }
    }
    .yellow-background {
      background-color: rgb(230, 177, 0);
    }
    .green-background {
      background-color: rgb(16, 167, 40);
    }
    .red-background {
      background-color: rgb(244, 5, 5);
    }
  }

  .profile-details-section {
    background-color: #f7f4f2;
    display: flex;
    padding: 20px;
    .profile-details {
      width: 80%;
      display: flex;
      align-items: center;
      .profile-picture {
        height: 60px;
        img {
          border-radius: 50%;
          height: 60px;
        }
      }
      .profile-name-contribution {
        padding: 0 10px;
        h4 {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }
        p {
          margin: 0;
          font-size: 16px;
        }
      }
    }

    .link-button {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background-color: rgb(243, 143, 70);
        width: 117px;
        border-radius: 7px;
        color: #ffffff;
        border: none;
        outline: none;
        font-size: 13px;
        height: 31px;
      }
    }
  }
}

.comments-section {
  padding: 20px 0;
  .comment-section-header {
    color: #424242;
    h1 {
      font-size: 22px;
      font-weight: 600;
    }
    p {
      font-size: 16px;
    }
  }
  .comment-section-content {
    .one-comment {
      margin-bottom: 20px;
      .comment-content-title {
        padding: 20px;
        display: flex;
        align-items: center;
        img {
          width: 41px;
          height: 41px;
          border-radius: 50%;
          object-fit: contain;
          background: #f7f4f2;
          margin: auto 0;
        }
        .contribution-name {
          width: 90%;
          padding-left: 10px;
          h3 {
            font-size: 16px;
            font-weight: 600;
            color: #424242;
            margin: 0;
          }
          p {
            color: #a3a3a3;
            font-size: 13px;
            margin: 0;
            position: relative;
            top: 5px;
          }
        }
      }
      .comment-content-body {
        p {
          color: #424242d9;
          font-size: 16px;
          padding: 0 30px 0 70px;
        }
      }
    }

    .contribution-comment-input-section {
      display: flex;
      width: 100%;
      .comment-author {
        width: 5%;
        img {
          height: 41px;
          border-radius: 50%;
          object-fit: contain;
          background: #f7f4f2;
          margin: auto 0;
        }
      }
      .comment-author-input {
        width: 95%;
        input {
          width: 100%;
          padding: 10px;
          outline: none;
          border: 1px solid #4242424d;
          border-radius: 5px;
        }
      }
    }
    .reply-button {
      margin: 20px 50px;
      button {
        background: #f38f46;
        border-radius: 5px;
        border: none;
        outline: none;
        color: #ffffff;
        padding: 5px 20px;
      }
    }
  }
}

.review-contribution-buttons {
  position: relative;
  left: 770px;
  button {
    margin: 20px 0;
    margin-top: 0;
    border-radius: 6px;
  }
  .reject-button {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  :hover {
    color: #f38f46;
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .review-contribution-buttons {
    left: 444px;
  }
}

@media (max-width: 768px) {
  .review-contribution-buttons {
    left: 150px;
  }
  .comments-section
    .comment-section-content
    .contribution-comment-input-section
    .comment-author {
    width: 12%;
  }
  .profile-link {
    .profile-details-section {
      .profile-contributions-indicators {
        display: none;
      }
    }
    .link-button {
      width: 30% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background-color: rgb(243, 143, 70);
        width: 120px;
        border-radius: 7px;
        color: #ffffff;
        border: none;
        outline: none;
        font-size: 13px;
        height: 31px;
      }
    }
  }
}

@media (max-width: 500px) {
  .review-contribution-buttons {
    left: 0;
  }
  .comments-section
    .comment-section-content
    .contribution-comment-input-section
    .comment-author {
    width: 16%;
  }
  .profile-link {
    .profile-details-section {
      display: block;
      .link-button {
        width: 100% !important;
        padding: 20px 0;
        padding-bottom: 10px;
        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .comments-section .comment-section-content .reply-button {
    margin: 20px 43px;
  }
}
