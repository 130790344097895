.amc__checkbox {
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;

  .checkbox_input {
    position: absolute;
    height: auto;
    border-radius: 5px;
    height: 24px !important;
    width: 100%;
    margin-top: 5px;
    transform: scale(2);
    opacity: 0;
    z-index: 3;
    cursor: pointer;
  }

  .checkbox_label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    display: inline-block;
    color: #666;
    margin-bottom: 0;
  }

  .checkbox_input:checked + label:before,
  .checkbox_input:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1.5px solid #a8a8a8;
    background: var(--backgroundColor);
  }

  .checkbox_input:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  .checkbox_input:checked + label:after,
  .checkbox_input:not(:checked) + label:after {
    content: "✔";
    position: absolute;
    left: 5px;
    transition: all 0.2s ease;
  }
}
