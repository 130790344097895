.media-library {
  margin-top: 6rem;
  width: 100%;
  display: flex;
}

.media-library__library-sidebar {
  width: 25%;
  min-height: calc(100vh - 6rem);
  background: #FFFFFF;
  box-shadow: 0px 2px 4px #00000029;
  padding: 1rem;
  z-index: 1;
}

.media-library__library-content {
  width: 75%;
}

@media (max-width: 992px) {
  .media-library {
    margin-top: 5rem;
  }
  .media-library__library-sidebar {
    display: none;
  }

  .media-library__library-content {
    width: 100vw;
  }
}
