.admin-club-questionnaires {
  width: 90% !important;
  margin: 0 auto;
  margin-top: 100px;
  .title {
    display: flex;
    padding: 30px 0;
    .questionnaire-name {
      display: flex;
      width: 89%;
      h1 {
        font-size: 28px;
        color: #424242;
        font-weight: 900;
      }
    }
  }
  .club-questionnaire-header {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 20px 0;
    .small-screens {
      display: none;
    }
    .questionnaires-count {
      width: 60%;
      p {
        margin: auto 0;
      }
    }
    .search-questionnaire {
      width: 40%;
    }
  }
  .admin-club-questionnaires-section {
    display: flex;
    flex-wrap: wrap;
    > .admin-member-card {
      background: #ffffff;
      width: 264px;
      height: 110px;
      margin: 15px;
      border-radius: 10px;
      .member-card-inside-content {
        height: 100%;
        box-shadow: 0px 2px 4px #0000000d;
        border-radius: 10px;
        display: flex;
        .admin-member-details {
          margin-left: 5px;
          width: 97%;
          height: 97%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 15px 0 10px 15px;
          cursor: pointer;
          > h1 {
            font-size: 16px;
            color: #424242f6;
            font-weight: 600;
          }
          > p {
            font-size: 13px;
            color: #a3a3a3;
            margin: 0 !important;
            padding: 5px 0;
          }
        }
        .edit-questionnaire {
          button {
            border: none;
            background: transparent;
            outline: none;
            .MuiSvgIcon-root {
              width: 1.3rem;
              height: 1.3rem;
              color: rgba(0, 0, 0, 0.514);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 590px) {
  .admin-club-questionnaires {
    margin-top: 80px !important;
    .bigger-screens {
      display: none !important;
    }
    .title {
      display: block;

      .questionnaire-name {
        h1 {
          color: #424242;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .edit-button {
        width: 100%;
        padding-top: 30px;
      }
    }
    .club-questionnaire-header {
      display: inline-block;
      margin-top: 0;
      .small-screens {
        display: block;
        margin-top: 20px;
      }
      .questionnaires-count {
        width: 100%;
        display: flex;
        p {
          margin: auto 0;
          width: 50%;
          font-size: 13px;
        }
        .edit-button {
          width: 50%;
          button {
            width: 100%;
            height: 30px;
            margin: auto 0;
            padding: 0;
            border-radius: 7px;
            font-size: 13px;
          }
        }
      }
      .search-questionnaire {
        width: 100%;
      }
    }
    .admin-club-questionnaires-section {
      display: block;
      .admin-member-card {
        width: 100%;
        margin: 15px 0;
        .member-card-inside-content {
          .admin-member-details {
            > h1 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
