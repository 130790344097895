.how-to-participate-page {
  padding: 20px 50px;
  .arrow-back-icon {
    color: #ddd;
  }
  .how-to-participate-header {
    padding: 20px 0;
    .header-title {
      h1 {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .title-description {
      display: flex;
      align-items: center;
      p {
        margin: auto 0;
        width: 70%;
      }
      .search-participants {
        width: 30%;
      }
    }
  }
  .actions-section {
    .section-title {
      p {
        padding: 0 !important;
        margin: 0 !important;
      }

      .export-btn {
        background-color: #f38f46;
        height: 32px;
        padding: 0 1rem;
        border: 0;
        border-radius: 3px;
        color: #ffffff;
      }
    }
    .section-actions {
      .steps {
        grid-gap: 50px;
        .step {
          border: 0.5px solid #e3e3e3;
          border-radius: 10px;
          box-shadow: 0px 2px 4px #00000029;
          padding: 20px;
          margin: 10px 0;
          background-color: #ffffff;
          p {
            margin-top: 10px;
          }
        }
        h1 {
          font-size: 16px;
          color: #f38f46;
          font-weight: 600;
        }
      }
    }
  }
  .add-new-action {
    padding: 20px 0;
    padding-bottom: 40px;
    button {
      display: flex;
      color: #f38f46;
      align-items: center;
      padding: 0;
      margin: 0;
      border: none;
      background: transparent;
      outline: none;
      p {
        margin: auto 0;
      }
    }
  }
}

.step-edit-buttons {
  display: flex;
  align-items: center;
  h1 {
    margin: auto 0;
    width: 80%;
  }
  button {
    background: transparent;
    border: none;
    outline: none;
    margin: auto 0;
    color: #a8a8a8;
    height: 24px;
    width: 10px;
    margin: 0 13px;
  }
}

@media (max-width: 786px) {
  .how-to-participate-page {
    padding: 10px 5px;
    .how-to-participate-header {
      .title-description {
        display: block;
        p {
          margin: unset;
          width: 100%;
        }
        .search-participants {
          padding: 10px 0;
          width: 100%;
        }
      }
    }
    .actions-section {
      .section-actions {
        .steps {
          grid-gap: unset;
          .step {
            margin: 20px 0;
          }
          h1 {
            font-size: 14px;
            color: #f38f46;
            font-weight: 600;
          }
        }
      }
    }
    .add-new-action {
      padding-bottom: 20px;
      button {
        display: flex;
        color: #f38f46;
        align-items: center;
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;
        outline: none;
        p {
          margin: auto 0;
        }
      }
    }
  }
}
