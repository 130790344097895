.questionnairePage {
  .editor-class {
    background-color: #fff;
    min-height: 160px;
    padding: 0 1rem;
    border-radius: 10px;
    border: 1px solid #fff;
  }

  .image-upload-preview {
    width: 150px;
    height: 150px;
  }

  .custom-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .width-unset {
    width: unset;
  }
}
