.promotions-table {
  .w-10 {
    width: 10%;
  }

  p {
    margin-bottom: 0;
  }

  .gap-5 {
    gap: 0.5rem;
  }

  .gap-10 {
    gap: 1rem;
  }

  .gap-15 {
    gap: 1.5rem;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
  }

  .toggle-output-btn,
  .btn-no-styles {
    background: none;
    border: none;
  }

  .success-badge {
    background-color: #10a72821;
    border: 1px solid #10a728;
    border-radius: 3px;
    color: #10a728;
    font-size: 12px;
  }

  .danger-badge {
    background-color: #ff0d0d21;
    border: 1px solid #ff0d0d;
    border-radius: 3px;
    color: #ff0d0d;
    font-size: 12px;
  }

  .filter-output {
    .output-dropdown {
      box-shadow: 0px 6px 12px #00000040;
      border: 1px solid #42424245;
      border-radius: 3px;
    }
  }

  .output-item {
    .cover-image {
      height: 40px;
      width: 40px;
      border-radius: 50px;
    }

    .club-name,
    .last-edited-text {
      font-size: 12px;
      color: #a3a3a3;
    }
  }
}
