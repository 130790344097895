.success-modal-dialog,
.error-modal-dialog {
  margin-top: 35vh;
}

.modal-max-width {
  max-width: 700px;

  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #a3a3a34d;
  }
}

.checkbox-container {
  width: 20px;
  height: 20px;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 12px;

  .input-checkbox-option {
    transform: scale(2);
    opacity: 0;
    z-index: 3;
  }

  .check {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: 2px;
  }

  .input-checkbox-option:checked + .check {
    background: #10a728;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.modal-content {
  border-top: 8px solid #f38f46;
  padding: 2rem 2rem 1rem;

  .font-small {
    font-size: 12px;
    font-style: italic;
  }

  .success-message,
  .error-message {
    .max-width-wrapper {
      max-width: 350px;
      margin: 0 auto;
      text-align: center;

      .btn-action {
        background-color: #f38f46;
        color: #fff;
        height: 30px;
        padding: 0 1rem;
        border: 0;
        border-radius: 3px;
      }
    }
  }

  .export-modal-body {
    header {
      font-size: 18px;
    }

    .body {
      background-color: #a3a3a314;
      border: 1px solid #a3a3a34d;
      border-radius: 3px;
      color: #424242;
      font-size: 14px;
    }

    .modal__footer {
      .btn-cancel {
        background: none;
        border: 0;
        padding: 0;
        margin-right: 1.2rem;
      }

      .btn-done {
        background-color: #f38f46;
        color: #fff;
        height: 30px;
        padding: 0 1rem;
        border: 0;
        border-radius: 3px;
      }
    }
  }
}
