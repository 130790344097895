.not-found {
    height: 100vh;
    background-image: url("../assets/404_page_not_found.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

.back {
    padding: 3rem;
}

.back:hover {
    cursor: pointer;
    text-decoration: underline;
}

.back-link {
    color: var(--textPrimary);
}

.back-link:hover {
    color: var(--textPrimary);
}
