.data-collection-manager {
    margin-top: 4em;
    padding: 24px;
    .top-section{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .text-section{
            width: 85%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h5{
                color: #424242;
                font-size: 16px;
                font-weight: 600;
            }
            p{
                color: #424242;
                font-size: 16px;
                font-weight: 400;
            }

        }
        a{
            all: unset;
            cursor: pointer;
            background-color: #f39550;
            border-radius: 7px;
            padding: 1px 14px;
            height: 40px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .switch-section{
        width: 100%;
        border: 2px solid #424242;
        border-radius: 10px;
        display: grid;
        padding: 2px;
        grid-template-columns: 1fr 1fr 1fr;
        button{
            all: unset;
            cursor: pointer;
            color: #424242;
            height: 40px;
            // background-color: #f39550;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
        }
        button.active{
            color: #ffffff;
            background-color: #f39550;
        }

    }
    .card-section{
        display: flex;
        flex-wrap: wrap;
        a{
            all: unset;
            cursor: pointer;
            border: 2px solid #f39550;
            color: #f39550;
            font-size: 12px;
            padding: 4px 8px;
            border-radius: 7px;
            svg{
                fill: #f39550;
            }
        }
    }
}