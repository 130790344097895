.admin-club-activities {
  width: 90% !important;
  margin-top: 100px !important;
  margin: 0 auto;
  .title {
    display: flex;
    padding: 30px 0;
    .club-name {
      display: flex;
      width: 89%;
      button {
        color: #a8a8a8;
      }
      h1 {
        color: #424242;
        font-weight: 600;
        font-size: 28px;
      }
    }
  }
  .activities-section {
    .activities-header-details {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 20px;
      .small-screens {
        display: none;
      }
      .activities-count {
        width: 60%;
        p {
          margin: auto 0;
        }
      }
      .search-activities {
        width: 40%;
      }
    }
    .club-activities-cards {
      display: flex;
      flex-wrap: wrap;
      padding-top: 50px;
      .one-activity-card {
        width: 200px;
        height: 164px;
        margin: 20px 20px 30px 10px;
        img {
          max-width: 200px;
          height: 134px;
        }
        h1 {
          font-size: 15px;
          padding: 10px 0;
        }
      }
    }
  }
}

@media (max-width: 590px) {
  .admin-club-activities {
    .title {
      .club-name {
        h1 {
          color: #424242;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
    margin-top: 100px;
    padding: 5px;
    .activities-section {
      margin-top: 10px;
      .activities-header-details {
        display: inline-block;
        .bigger-screens {
          display: none !important;
        }
        .small-screens {
          display: block;
          margin-top: 20px;
        }
        .activities-count {
          width: 100%;
          display: flex;
          p {
            margin: auto 0;
            width: 50%;
            font-size: 13px;
          }
          .edit-button {
            width: 50%;
            button {
              width: 100%;
              height: 30px;
              margin: auto 0;
              padding: 0;
              border-radius: 7px;
              font-size: 13px;
            }
          }
        }
        .search-activities {
          width: 100%;
        }
      }
    }
    .club-activities-cards {
      display: flex;
      justify-content: left;

      .one-activity-card {
        margin-right: 0 !important;
        text-align: center;
        h1 {
          font-size: 15px;
          padding: 10px 5px 0 5px !important;
        }
      }
    }
  }
}

@media (max-width: 410px) {
  .club-activities-cards {
    display: flex;
    justify-content: center !important;
  }
}
