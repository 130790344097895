.admin-club-layout {
  width: 90% !important;
  margin: 0 auto;
  margin-top: 100px;
  .club-layout-details {
    .title {
      display: flex;
      padding: 30px 0;
      .club-name {
        display: flex;
        width: 89%;
        h1 {
          font-size: 28px;
          color: #424242;
          font-weight: 900;
        }
      }
    }
  }
  .configurable-settings {
    padding: 40px 0;
    .advanced-settings {
      padding: 20px 0;
      display: flex;
      align-items: center;
      .left-details {
        width: 98%;
        h1 {
          margin: auto 0;
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 5px;
        }
        p {
          padding-top: 5px;

          margin: auto 0;
          font-size: 15px;
        }
      }
      .right-details {
        float: right;
      }
    }
  }
}

@media (max-width: 515px) {
  .admin-club-layout {
    width: 90% !important;
    margin: 0 auto;
    margin-top: 100px;
    .club-layout-details {
      .title {
        display: flex;
        padding: 0;
        .club-name {
          display: flex;
          width: 89%;
          margin: auto 0;

          h1 {
            font-size: 16px;
            color: #424242;
            font-weight: 900;
          }
        }
        .edit-button {
          width: 120px;
          height: 30px;
          button {
            width: 120px;
            height: 30px;
            font-size: 13px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    .configurable-settings {
      padding: 40px 0;
      .advanced-settings {
        padding: 15px 0;
        display: flex;
        align-items: center;
        .left-details {
          width: 98%;
          h1 {
            font-size: 13px;
            font-weight: 600;
          }
          p {
            font-size: 13px;
          }
        }
        .right-details {
          float: right;
          display: none;
        }
      }
    }
  }
}
