.participantsPage {
  background-color: red;
  margin: 1rem;
}
.participant-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.participant-card {
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  height: 120px;
  padding: 0 1rem;
}

.participant-page-header {
  display: flex;
}

.participant-page-header > .participant-back-title {
  width: 89%;
}

.participants-header-details {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}

.participants-header-details > .search-participants {
  width: 40%;
}

.participants-header-details > .participants-count {
  width: 60%;
}

.participants-header-details > .participants-count > p {
  margin: auto 0;
}

.participants-header-details > .small-screens {
  display: none;
}

/* Small devices (landscape phones, 320px and up) */
@media (min-width: 320px) {
  .participant-page-search {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem;
  }

  .participant-page-search search-box {
    width: calc(500px - 4px);
  }
  .participant-page-actions {
    display: flex;
    flex-direction: column;
    margin: 0 0 0.5rem 0;
  }
  .participant-page-actions div {
    display: flex;
    justify-content: space-between;
  }
  .participant-card__width {
    width: calc(100% - 4px);
    margin: 8px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .participant-card {
    width: calc(50% - 16px);
    margin: 8px;
  }

  .participant-page-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 0.5rem 0;
  }
  .participant-page-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin: 20px 0;
  }

  .participant-page-search search-box {
    width: 500px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .participant-card {
    width: calc(25% - 16px);
    margin: 8px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .participant-page-header {
    display: block;
  }

  .participant-page-header > .participant-back-title {
    width: 100%;
  }
  .participant-page-header > .edit-button {
    padding: 20px 0;
  }
  .participant-page-header > .edit-button > button {
    padding: 7px;
    margin: 0;
    width: 100%;
  }
  .participants-header-details {
    display: inline-block;
  }
  .participants-header-details > .bigger-screens {
    display: none;
  }
  .participants-header-details > .small-screens {
    display: block;
    margin-top: 20px;
  }
  .participants-header-details > .search-participants {
    width: 100%;
  }
}
