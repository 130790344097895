.import-users-page {
  margin-top: 100px;
  padding: 0 2rem;

  // ========== NAVIGATION ==========
  .nav-links {
    box-shadow: none;
    height: unset;
    background: none;
  }
  // ========== NAVIGATION ==========

  // ========== IMPORT HEADER SECTION ==========
  .import-header {
    .max-width-wrapper {
      max-width: 500px;
      width: 100%;

      .title {
        font-weight: bold;
        font-size: 25px;
        margin-bottom: 1.5rem;
      }

      .description {
        font-size: 16px;
        // color: lavender;
      }
    }
  }
  // ========== IMPORT HEADER SECTION ==========

  // ========== IMPORT HEADER BODY ==========
  .import-body {
    padding: 3rem 0;

    .text-decoration-underline {
      text-decoration: underline;
    }

    .max-width-wrapper {
      max-width: 500px;
      width: 100%;

      .import-members {
        margin-bottom: 3rem;

        .import-again-btn {
          height: 42px;
          border: 1px solid #f38f46;
          border-radius: 6px;
          color: #f38f46;
          background-color: transparent;
          padding: 0 1rem;
        }

        .import-header {
          font: normal normal 600 20px/32px Open Sans;
          color: #424242;
        }

        .file-upload-wrapper {
          padding: 1rem;
          background: #efe5db80 0% 0% no-repeat padding-box;
          border: 0.30000001192092896px solid #4242424d;
          border-radius: 6px;
          color: #424242;

          p:first-child {
            font: normal normal 600 16px/32px Open Sans;
            letter-spacing: 0px;
            margin-bottom: 0.5rem;
          }

          span:first-child {
            font: normal normal normal 14px/32px Open Sans;
            display: block;
            margin-bottom: 0.5rem;
          }

          .file-upload-input {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 0.6000000238418579px dashed #42424245;
            border-radius: 6px;
            padding: 2rem;
            margin-top: 0.75rem;

            .browse-files {
              background: #f38f46 0% 0% no-repeat
                padding-box;
              border-radius: 6px;
              margin-top: 0.5rem;
              border: none;
              color: #ffffff;
              padding: 0.5rem 1rem;
            }

            .format-msg {
              text-align: left;
              font: normal normal normal 12px/32px Open Sans;
              letter-spacing: 0px;
              text-align: center;
              color: #424242;
            }
          }
        }
      }

      .assign-users-club {
        .import-btn {
          font: normal normal normal 16px/32px Open Sans;
          letter-spacing: 0px;
          color: #ffffff;
          background: #f38f46 0% 0% no-repeat padding-box;
          border-radius: 6px;
          border: none;
          min-width: 100px;

          &.disabled {
            opacity: 0.6;
          }
        }

        .header {
          font: normal normal 600 20px/32px Open Sans;
          letter-spacing: 0px;
          color: #424242;

          .desc {
            font: normal normal normal 16px/32px Open Sans;
            letter-spacing: 0px;
            color: #4242428c;
          }

          button.assign-btn {
            height: 38px;
            background: #f7eae0 0% 0% no-repeat padding-box;
            border: 2px solid #a3a3a357;
            border-radius: 6px;
            padding: 0 1rem;
          }

          button.active {
            background-color: #f38f46;
            color: #fff;
          }
        }

        .club-list-wrapper {
          border-radius: 10px;
          background-color: #fff;
          padding: 1rem 0.5rem 0;
          color: #424242;

          .header {
            font: normal normal 600 20px/32px Open Sans;
            letter-spacing: 0px;
          }

          .desc {
            text-align: left;
            font: normal normal normal 16px/32px Open Sans;
            letter-spacing: 0px;
            color: #424242b3;
          }

          .club-search-form {
            display: flex;
            align-items: center;

            input {
              height: 42px;
              padding-left: 0.75rem;
              background: #ffffff 0% 0% no-repeat
                padding-box;
              border: 1px solid #a3a3a366;
              border-radius: 6px;
              flex-grow: 1;
              border-right: 0;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            button {
              background: none;
              border: 1px solid #a3a3a366;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.5rem 0.75rem;
              border-left: 0;
            }
          }

          .no-selected-club {
            padding: 3.5rem 0;
            font: normal normal 600 16px/21px Open Sans;
            letter-spacing: 0px;
            color: #424242;

            .msg {
              max-width: 250px;
              margin: 0 auto;
            }
          }

          .club-section {
            .club-list-wrapper {
              height: 400px;
              overflow-y: auto;

              .clubs {
                .club-list-item {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 1.2rem;

                  .club-avatar {
                    object-fit: cover;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                  }

                  .club-name {
                    font: normal normal 600 16px/32px Open
                      Sans;
                    letter-spacing: 0px;
                    color: #424242;
                  }

                  .info-text {
                    font: italic normal normal 12px/32px
                      Open Sans;
                    letter-spacing: 0px;
                    color: #a3a3a3;
                  }

                  .club-assign-btn {
                    font: normal normal normal 16px/32px
                      Open Sans;
                    letter-spacing: 0px;
                    color: #ffffff;
                    background: #f38f46 0% 0% no-repeat
                      padding-box;
                    border-radius: 6px;
                    border: none;
                    margin-left: 1rem;

                    &.assigned {
                      height: 38px;
                      background: #f7eae0 0% 0% no-repeat
                        padding-box;
                      border: 2px solid #a3a3a357;
                      border-radius: 6px;
                    }

                    &.selected {
                      background: #f7eae0 0% 0% no-repeat
                        padding-box;
                      border: 2px solid #a3a3a357;
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }

          .action-btn {
            .remove {
              height: 38px;
              border: 1px solid #f38f46;
              border-radius: 6px;
              color: #f38f46;
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  // ========== IMPORT HEADER BODY ==========
}
