.activityReadMore {
  background-color: #ffffff;
  padding: 20px 40px;

  border-radius: 10px;
  box-shadow: 0px 2px 4px #00000029;
}

.activityReadMore .headerTitle {
  border-bottom: solid 1px #e6e5e2;
  display: flex;
  justify-content: space-between;
}

.activityReadMore .headerTitle > h1 {
  font-size: 16px;
  font-weight: 600;
}

.activityReadMore .headerTitle > h1::first-letter {
  text-transform: capitalize;
}

.activityReadMore .body > p {
  font-size: 16px;
  line-height: 26px;
}

.activityReadMore .body > .steps .step {
  border: 0.5px solid #e3e3e3;
  border-radius: 10px;
  box-shadow: 0px 2px 4px #00000029;
  padding: 20px;
  margin: 20px;
}

.activityReadMore .body > .steps h1 {
  font-size: 16px;
  color: #f38f46;
  font-weight: 600;
}

.activityReadMore .body > .steps p {
  font-size: 16px;
  line-height: 26px;
}

@media (max-width: 599px) {
  .activityReadMore {
    background-color: transparent;
    padding: 20px 0;
    border-radius: 0;
    box-shadow: none;
  }

  .activityReadMore .headerTitle > h1,
  .activityReadMore .body > p,
  .activityReadMore .body > .steps h1,
  .activityReadMore .body > .steps p {
    font-size: 13px;
  }

  .activityReadMore .body > .steps .step {
    border: 0.5px solid #e3e3e3;
    border-radius: 7px;
    box-shadow: 0px 2px 4px #00000029;
    padding: 20px;
    margin: 10px 0;
    background: #ffffff;
  }

  .activityReadMore .body > .steps {
    padding-top: 0;
  }
}
