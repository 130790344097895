.viewSingleActivity {
  width: 90% !important;
  margin: 100px auto;
}

.viewSingleActivity .title {
  display: flex;
  padding: 50px 0;
}
.viewSingleActivity .title > h1 {
  font-size: 28px;
  color: #424242;
  font-weight: 900;
}
.participateButtonSection {
  text-align: center;
  padding: 50px 0;
  width: 100%;
}

.participateButtonSection > button {
  background-color: #f38f46;
  outline: none;
  border: none;
  border-radius: 10px;
  height: 40px;
  width: 250px;
  color: #ffffff;
}

@media (max-width: 599px) {
  .viewSingleActivity {
    margin: 50px 20px;
  }

  .viewSingleActivity .title > h1 {
    font-size: 15px;
    color: #424242;
    font-weight: 900;
  }
  .activityDetailsSection,
  .activityMoreInformationSection,
  .activityParticipantsSection,
  .viewSingleActivity .title {
    margin: 0px;
    width: 100%;
  }
  .participateButtonSection {
    text-align: center;
    padding: 30px 0;
    width: 100%;
    margin: auto;
  }

  .participateButtonSection > button {
    width: 100%;
    height: 40px;
    border-radius: 7px;
    font-size: 13px;
    padding: 0;
    margin: 0;
  }

  .viewSingleActivity .title button {
    border: 1px solid green;
  }
}
