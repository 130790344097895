.contribute-to-lessons {
  background-color: #f7f4f2;

  // ========= LESSONS OVERLAY ========= //
  .lessons-overlay {
    background-color: #00000073;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 100;

    .content-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      max-width: 400px;
      width: 100%;
      height: 400px;
      border-radius: 6px;
      padding: 3rem 1rem;

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .progress {
          height: 100px;
          width: 100px;
          margin-bottom: 1.5rem;
          background-color: transparent;
        }

        .details {
          margin-bottom: 1.5rem;
          text-align: center;

          .title {
            font-size: 25px;
            font-weight: bold;
          }

          .desc {
            margin-bottom: 0;
            font-size: 14px;
            color: #595959;
          }
        }
        .btn-wrapper {
          display: flex;
          flex-direction: column;
          padding: 0 12px;

          .next-lesson-btn,
          .submit-lesson-btn {
            background-color: #f39550;
            width: 100%;
            height: 42px;
            cursor: pointer;
            color: #fff;
            padding: 0 1.5rem;
            border: 0;
            border-radius: 4px;
            font-weight: 600;
            margin-bottom: 1rem;
          }

          .cancel-btn {
            background: none;
            border: 0;
            padding: 0;
          }
        }
      }
    }
  }

  // ========= MAIN NAV ========= //
  .main-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 62px;
    display: flex;
    align-items: center;
    background-color: #f39550;
    color: #fff;
    padding: 0 1.5rem;

    .link-wrapper {
      width: fit-content;

      button {
        font-size: 16px;
        background: none;
        border: none;
        padding: 0;
        color: #fff;
        display: flex;
        align-items: center;
        margin-right: 1rem;

        svg {
          fill: #fff;
        }
      }
    }

    .wrapper {
      flex-grow: 1;

      P {
        font-size: 24px;
        margin-bottom: 0;
        text-align: center;

        @media (max-width: 576px) {
          font-size: 20px;
          text-align: left;
        }
      }
    }
  }

  .section-wrapper {
    display: flex;
    min-height: calc(100vh - 62px);
    margin-top: 62px;

    // ========= SIDEBAR ========= //
    aside {
      width: 300px;
      padding: 1rem;
      overflow-y: auto;
      height: 100vh;
      position: fixed;
      left: 0;
      transition: all 0.2s;

      &.is-closed {
        left: -300px;
      }

      .wrapper {
        .name-of-action {
          border-left: 2px solid #f39550;
          padding: 1.5rem 12px;
          background-color: #fff;
          display: flex;
          font-size: 14px;

          .step {
            flex-shrink: 0;
            margin-right: 10px;
          }

          .desc {
            span {
              &:first-child {
                font-weight: 600;
                display: block;
              }

              &:last-child {
                font-size: 12px;
              }
            }
          }
        }

        .searchBar {
          margin: 1rem 0;

          div {
            display: flex;
            align-items: center;

            input {
              height: 40px;
              border: 0;
              padding-left: 1rem;
              flex-grow: 1;
              outline: 0;
            }

            .searchBtn {
              height: 40px;
              background-color: #fff;
              border: 0;
              padding-right: 6px;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        .lessons-wrapper {
          .lesson-item {
            padding: 12px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-bottom: 1px solid #cccccc;

            .checkbox-wrapper {
              flex-basis: 40px;
              display: flex;
              align-items: flex-start;
              justify-content: center;
            }

            .desc {
              flex-grow: 1;
              //   font-size: 14px;
              font-weight: 600;

              p {
                margin-bottom: 0;

                &:last-child {
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    // ========= MAIN CONTENT ========= //
    main {
      margin-left: 300px;
      flex-grow: 1;
      color: #424242;
      background-color: #fff;
      position: relative;
      transition: all 0.2s;

      &.is-closed {
        margin-left: 0;
      }

      header {
        // position: absolute;
        width: 100%;
        right: 0;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px -2px 4px #0000000d;
        padding: 0 1rem;
        border-bottom: 1px solid #cccccc;
        position: sticky;
        top: 62px;
        background-color: #fff;

        .title-wrapper {
          display: flex;
          align-items: center;
          .toggle-btn {
            background: none;
            border: 0;
            padding: 0;
            margin-right: 1rem;
            svg{
              height:20px;
              width: 20px;
            }
          }
          span.hide{
            @media (max-width: 576px) {
              display: none;
            }
          }
        }

        .progress {
          display: none;

          @media (min-width: 576px) {
            display: block;
          }
        }
      }

      .content {
        padding: 3rem 1.5rem;
        text-align: justify;
        line-height: 30px;
        min-height: calc(
          100vh - 62px - 130px
        ); // 100vh - Navbar Height - (Main Nav Height + Footer Height)

        .wrapper {
          max-width: 600px;
          margin: 0 auto;

          .article-image {
            margin-bottom: 1.5rem;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      footer {
        width: 100%;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: 0px -2px 4px #0000000d;
        border-top: 0.10000000149011612px solid #cccccc;
        height: 80px;

        .cta-button {
          cursor: pointer;

          .arrow-left-icon {
            margin-left: 12px;
          }
        }
      }
    }
  }
}
