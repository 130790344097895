.viewSingleClub {
  margin: 100px;
}

.viewSingleClub .title {
  display: flex;
  padding: 50px 0 5px;
}

.viewSingleClub .title > h1 {
  font-size: 28px;
  color: #424242;
  font-weight: 900;
}

.join-button-section {
  text-align: center;
  padding: 50px;
  width: 1341px;
  margin: auto;
}

.join-button-section > button {
  background-color: #f38f46;
  outline: none;
  border: none;
  border-radius: 10px;
  height: 40px;
  width: 250px;
  color: #ffffff;
  margin-right: 100px;
}

.club-details-section,
.club-activities-section,
.club-members-section,
.outputs-row-section,
.viewSingleClub .title {
  margin: auto;
  width: 1200px;
}

.outputs-row-section {
  width: 100%;
}

@media (max-width: 599px) {
  .viewSingleClub {
    margin: 50px 20px;
  }

  .viewSingleClub .title > h1 {
    font-size: 15px;
    color: #424242;
    font-weight: 900;
  }

  .club-details-section,
  .club-activities-section,
  .club-members-section,
  .outputs-row-section,
  .viewSingleClub .title {
    margin: 0px;
    width: 100%;
  }

  .join-button-section {
    text-align: center;
    padding: 30px 0;
    width: 100%;
    margin: auto;
  }

  .join-button-section > button {
    width: 100%;
    height: 40px;
    border-radius: 7px;
    font-size: 13px;
    padding: 0;
    margin: 0;
  }

  .viewSingleClub .title button {
    border: 1px solid green;
  }
}
