.payment-view {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 5px #0000000d;
  padding: 2rem;
  min-height: 60vh;

  .confirm-header {
    font-size: 1.2rem;
    font-weight: bold;
    padding-bottom: 1.5rem;
  }

  .wrapper {
    padding-top: 2rem;
    margin-bottom: 1.5rem;
    border-top: 1px solid #e6e5e2;
    border-bottom: 1px solid #e6e5e2;

    .description,
    .frequency,
    .ref_number {
      margin-bottom: 2rem;

      header {
        color: #a3a3a3;
        font-size: 14px;
      }

      p {
        color: #424242;
      }
    }
  }

  .total {
    header {
      color: #a3a3a3;
    }

    .amount {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
}

.action-btns {
  margin-top: 3rem;
  font-size: 16px;

  .cancel-btn {
    color: var(--primaryColor);
    font-size: 16px;
  }

  .submit-btn:hover {
    background-color: var(--primaryColor);
    color: #fff;
  }
}

// ========= PAYMENT SUCCESS/ERROR OVERLAY ========= //
.payment-status-overlay {
  background-color: #00000073;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1050;

  .content-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    max-width: 760px;
    width: 100%;
    height: 400px;
    border-radius: 6px;
    padding: 3rem 1rem;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .next-action-btn {
        background-color: #f39550;
        width: 200px;
        height: 42px;
        cursor: pointer;
        color: #fff;
        padding: 0 1.5rem;
        border: 0;
        border-radius: 4px;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      .cancel-btn {
        background: none;
        border: 0;
        padding: 0;
      }
    }
  }
}
