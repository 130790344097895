div.Activity_ {
  width: 100%;
  padding: 25px 0;
  img.activityPicture {
    background-color: #e8daca;
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }
  .activityStatus {
    display: flex;
    flex-direction: column;
    width: 100%;
    p,
    a {
      font-size: 13px;
      margin: 0 0 5px;
    }
    p.status {
      /* line-height: 40px; */
      color: #424242;
      span {
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: red;
      }
      span.active {
        background-color: #10a728;
      }
    }
    p.type {
      /* line-height: 18px; */
      font-style: italic;
      color: #a3a3a3;
    }
    .clubInfo {
      display: flex;
      img {
        height: 20px;
        width: 20px;
        margin: 0;
      }
      p {
        font-weight: bold;
      }
    }
    a {
      color: #f38f46;
      margin: 10px 0 0;
    }
  }
  .activityDescription {
    width: 100%;
    h1 {
      font-size: 11px;
      font-weight: bold;
      line-height: 20px;
      color: #424242;
    }
    p {
      font-size: 13px;
      line-height: 22px;
      color: #4b515d;
    }
  }
  .mobileLayout {
    .mobilegroup {
      width: 100%;
      display: flex;
      padding: 0 0 15px;
      margin: 0 0 15px;
      border-bottom: thin solid #e2e0dc;
      img.activityPicture {
        margin-right: 10px;
      }
    }
  }
  .desktopLayout {
    display: none;
  }
  @media (min-width: 1024px) {
    padding: 30px 40px;
    background-color: white;
    img.activityPicture {
      height: 200px;
      width: 200px;
    }
    .activityStatus {
      flex-direction: row;
      position: relative;
      p,
      a {
        font-size: 16px;
      }
      p {
        margin: 0 30px 0 0;
      }
      a {
        position: absolute;
        margin: 0;
        right: 0;
      }
      .clubInfo {
        img {
          margin: 0 10px 0 0;
        }
      }
    }
    .activityDescription {
      margin: 40px 0 0;
      h1 {
        font-size: 16px;
        line-height: 32px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .mobileLayout {
      display: none;
    }
    .desktopLayout {
      display: flex;
      img.activityPicture {
        margin: 0 50px 0 0;
      }
      .group {
        width: 100%;
      }
    }
  }
}

div.ActivityPlus {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  .unit {
    background-color: white;
    position: relative;
    padding: 15px 20px;
    color: #424242;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #00000029;
    .group {
      display: flex;
      align-items: center;
      p {
        font-size: 11px;
        margin: 0;
        color: #424242;
      }
      svg {
        height: 20px;
        width: 20px;
        margin: 0 6px 0 0;
        color: #f38f46;
      }
    }
    p {
      font-size: 15px;
      font-weight: 600;
      margin: 8px 0 0;
    }
    .line {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0;
    box-shadow: 0px 2px 4px #00000029;
    .unit {
      background-color: transparent;
      padding: 20px 30px;
      border-radius: 0;
      box-shadow: none;
      .group {
        p {
          font-size: 14px;
        }
        svg {
          height: 35px;
          width: 35px;
          margin: 0;
          position: absolute;
          right: 20px;
          top: 35px;
        }
      }
      p {
        font-size: 18px;
      }
      .line {
        display: block;
        position: absolute;
        right: 0;
        top: 10px;
        height: calc(100% - 20px);
        width: 2px;
        background-color: #f2ebe5;
      }
    }
  }
}
