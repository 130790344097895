.resource-page {
  max-width: 1400px;
  margin: 0 auto;
}

.resource-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.resource-card {
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  height: 120px;
  padding: 0 1rem;
  /* cursor: pointer; */
}


/* Small devices (landscape phones, 320px and up) */
@media (min-width: 320px) {
  .resource-page-search {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 1rem;
  }

  .resource-page-search search-box {
      width: calc(500px - 4px);
  }
  .resource-page-actions {
      display: flex;
      flex-direction: column;
      margin: 0 0 .5rem 0;
  }
  .resource-page-actions div {
      display: flex;
      justify-content: space-between;
  }
  .resource-card__width {
      width: calc(100% - 4px);
      margin: 8px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .resource-card {
      width: calc(50% - 16px);
      margin: 8px;
    }

    .resource-page-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 0 .5rem 0;
  }
    .resource-page-search {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      margin: 20px 0;
  }

  .resource-page-search search-box {
      width: 500px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .resource-card {
      width: calc(25% - 16px);
      margin: 8px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}