.gallery-modal .modal-dialog {
  width: 90vw;
  max-width: 90vw;
  min-height: 90vh;
}

.gallery-modal .modal-dialog .modal-content {
  height: 90vh;
  max-height: 90vh;
  min-height: 90vh;
  background-color: var(--backgroundColor);
}

.modal-container .nav-arrows {
  background-color: var(--backgroundColor);
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container .activity-stats .activity-stat-item {
  margin-right: 2rem;
  font-size: 12px;
}

.modal-container
  .activity-stats
  .activity-stat-item:last-child {
  margin-right: 0;
}

.modal-container .activity-content .activity-content-text {
  font-size: 14px;
}

.gallery-modal-right {
  height: calc(90vh - 0.5rem);
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--backgroundColor);
}

.gallery-modal-right::-webkit-scrollbar {
  width: 10px;
}

.gallery-modal-right::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.gallery-modal-right::-webkit-scrollbar-thumb {
  background: #888;
}

.gallery-modal-right::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-container .post-comment {
  margin-bottom: 1rem;
}

.modal-container .post-comment textarea {
  font-size: 14px;
}

@media (max-width: 599px) {
  .gallery-modal .modal-dialog .modal-content {
    margin-left: -0.5rem;
  }

  .gallery-modal-left {
    padding-left: 0;
    padding-right: 0;
  }

  .gallery-modal-right {
    padding-left: 2rem;
    padding-bottom: 1rem;
    height: auto;
    overflow: visible;
  }

  .gallery-modal .modal-dialog {
    width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
  }

  .modal-container .nav-arrows.arrow-right {
    margin-right: 1rem !important;
  }
}
