.landing-content h2 {
    font-size: 50px;
}
.landing-content p {
    font-size: 25px;
}

.about-section {
    background-image: url('../assets/bg-about.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    background-color: #E8E7E3;
}

.what-we-do, .how-we-do {
    padding: 5rem;
}

.what-we-do h3, .how-we-do h3 {
    font-size: 60px;
}

.what-we-do p, .how-we-do p {
    font-size: 30px;
}

.alumni-section {
    background-color: #EFDAC3;
    border-top: 1px solid #C6A177;
    border-bottom: 1px solid #C6A177;
}

.alumni-container, .socio-container {
    padding: 5rem;
}

.alumni-container p, .socio-container p {
    font-size: 30px;
}

.landing-bottom {
    background-color: #C7C3BA;
    background-image: url('../assets/footer.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
}

.landing-bottom .footer {
    padding-top: 10rem;
}

.card {
    box-shadow: 0px 3px 5px #00000029;
    border-radius: 5px;
}

.almanac-card {
    background: var(--primaryColor) 0% 0% no-repeat padding-box;
}

.career-card {
    background: #E2C3A1 0% 0% no-repeat padding-box;
}

.anthology-card {
    background: #EDDAC5 0% 0% no-repeat padding-box;
}

.gallery-card {
    background: #DFC3A4 0% 0% no-repeat padding-box;
}

.almanac-card .card-title, .career-card .card-title, .anthology-card .card-title, .gallery-card .card-title {
    font-size: 40px;
    font-weight: bold;
}

.almanac-card .card-text, .career-card .card-text, .anthology-card .card-text, .gallery-card .card-text {
    font-size: 25px;
}

.card-container {
    padding: 5rem;
}

.mini-logo img {
    width: 100px;
    height: 100px;
}

.links *, .links *:hover {
    color: var(--textPrimary);
}

footer {
    height: 60px;
    background: var(--primaryColor) 0% 0% no-repeat padding-box;
    border: 1px solid #C6A177;
}

.footer-text {
    font: Roman 20px/27px Avenir;
}

.discover-btn {
    width: fit-content;
}

.links-row {
    margin: 5rem;
}


/* Media Queries */
@media only screen and (max-width: 992px) {
    .card-container {
        padding: 0rem;
    }
    .mini-logo, .links {
        text-align: center;
        margin-bottom: 1rem;;
    }
    .mini-logo img {
        width: 69px;
        height: 69px;
    }
    .social-links {
        text-align: center;
    }
}
@media only screen and (max-width: 415px) {
    .landing-content {
        text-align: center;
    }
    .landing-content h2 {
        font-size: 30px;
    }
    .landing-content p {
        font-size: 19px;
    }
    .landing-content-links button {
        width: 8rem;
    }

    .about-section {
        margin: 0;
    }
    .what-we-do, .how-we-do {
        padding: 1rem;
    }
    .how-we-do {
        margin-top: 2rem;
    }
    .what-we-do h3, .how-we-do h3 {
        font-size: 30px;
    }
    .what-we-do p, .how-we-do p {
        font-size: 15px;
    }

    .about-section, .alumni-section, .socio-section {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .alumni-container, .socio-container {
        padding: 1rem;
    }
    .card-container {
        padding: 0rem;
    }
    .alumni-container h1, .socio-container h1 {
        font-size: 30px;
    }
    .alumni-container p, .socio-container p {
        font-size: 15px;
    }
    .card-container .card-title {
        font-size: 17px;
    }
    .card-container .card-text {
        font-size: 15px;
    }

    .links-row {
        margin: 0rem;
        padding: 4rem 0 1rem 0;
    }
    .links-row .links {
        font-size: 12px;
    }
    .links-row .links-text {
        padding: .3rem;
    }
    .footer h3 {
        font-size: 20px;
    }
    .footer-text {
        font-size: 12px;
    }
    .landing-bottom {
        background-image: none;
    }
    .landing-bottom .footer {
        padding-top: 3rem;
    }
    .mini-logo, .links {
        text-align: center;
        margin-bottom: 1rem;;
    }
    .mini-logo img {
        width: 69px;
        height: 69px;
    }
    .social-links {
        text-align: center;
    }
    .links-row {
        /* background-color: #C7C3BA; */
        background-image: url('../assets/footer.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
    }
}


.form-group.form-label-group:focus {
    border: #E59255 solid 1px;
    border-radius: 10px;
    position: relative;
}

.form-group.form-label-group label:focus {
    position: absolute !important;
    top: -10px !important;
    font-size: .7rem;
    left: 54px;
    padding: 0px 6px;
    background-color: #F2F2F2 !important;
    width: unset;
}
