#mobile-menu {
  display: none;
}

/* #F38F46*/
.verify-account {
  background-color: #F38F46;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* margin-top: 20px; */
  height: 40px;
}
@media (max-width: 991px) {
  .verify-account {
    height: 30px;
  }
}
.verify-account > p {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 16px;
}
@media (max-width: 599px) {
  .verify-account > p {
    font-size: 12px;
  }
}
@media (max-width: 449px) {
  .verify-account > p {
    font-size: 10px;
  }
}
@media (max-width: 349px) {
  .verify-account > p {
    font-size: 8px;
  }
}
.verify-account > p:nth-child(2) {
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

/* .navbar-brand-mobile, */
.search-button {
  display: none;
}

nav {
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  /* height: 100px; */
  height: 80px;
}

.navbar-nav__custom {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar img {
  width: 60px;
  height: 60px;
}

.navbar-toggler:focus {
  outline: none;
}

.nav-link img {
  width: 30px;
  height: 30px;
}

.nav-item {
  padding-left: 3rem;
  cursor: pointer;
}

.nav-item.nav-notifications .dropdown-toggle::after,
.nav-item.nav-create .dropdown-toggle::after,
.admin-create-post .dropdown-toggle::after {
  display: none;
}

.nav-item.nav-notifications .notifications-number-badge {
  font-size: 12px;
  background-color: var(--primaryColor);
  color: #ffffff;
  padding: 0.2rem;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 50%;
}

.nav-item.nav-notifications .notifications-dropdown,
.nav-create .create-dropdown,
.admin-create-post .create-dropdown {
  width: 460px;
  box-shadow: 0px 2px 5px #cccccc80;
  border-radius: 10px;
  border: none;
}

.dropdown-notification-item
  > .dropdown-notification-link
  > .post-item-author
  > .post-avatar
  > .img-fluid {
  max-width: unset;
}

.nav-create .create-dropdown,
.admin-create-post .create-dropdown {
  width: 300px;
}

.nav-create .create-dropdown .dropdown-item,
.admin-create-post .create-dropdown .dropdown-item {
  padding: 1rem;
}

.nav-create .create-dropdown .dropdown-item:focus,
.admin-create-post .create-dropdown .dropdown-item:focus {
  background-color: #cccccc80;
  color: unset;
}

.nav-item.nav-notifications
  .notifications-dropdown
  .notifications-dropdown-title {
  padding: 1rem;
  border-bottom: 1px solid var(--textSecondary);
  color: var(--textPrimary);
}

.nav-item.nav-notifications
  .notifications-dropdown
  .dropdown-notification-item {
  font-size: 13px;
  margin: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--textSecondary);
}

.nav-item.nav-notifications
  .notifications-dropdown
  .dropdown-notification-item:last-child {
  border-bottom: unset;
}

.nav-item.nav-notifications
  .notifications-dropdown
  .dropdown-notification-link {
  color: var(--textPrimary);
  text-decoration: none;
}

.nav-item.nav-notifications
  .notifications-dropdown
  .view-all-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryColor);
  border-top: 1px solid var(--textSecondary);
  padding: 1rem;
  text-decoration: none;
}

.active-link {
  color: var(--primaryColor);
}

.menu-text {
  font-size: 12px;
  color: var(--textPrimary);
}

.active-link .menu-text {
  color: var(--primaryColor);
}

.search-button img {
  width: 24px !important;
  height: 24px !important;
}

.search-bar > .rectangle-search-bar > .search-and-discover,
.search-bar > .rectangle-search-bar > .MuiSvgIcon-root {
  display: none;
}

.profile-image {
  display: none;
}

.profile-web {
  display: block;
}

/* Media Queries */
@media only screen and (max-width: 991px) {
  #navbarSupportedContent
    > .all-nav-items
    > ul
    > li:nth-child(3)
    > div
    > div {
    position: absolute;
    left: -122px;
  }
  #navbarSupportedContent
    > .all-nav-items
    > ul
    > li.nav-item.nav-notifications
    > div
    > div {
    position: absolute;
    left: -84vw;
    width: 100%;
  }

  .navbar {
    /* height: 5rem; */
    height: 100px;
  }

  .navbar-brand {
    display: none;
  }

  .search-button {
    display: block;
  }
  #navbarSupportedContent
    > .all-nav-items
    > ul
    > li:nth-child(3) {
    display: none;
  }

  .css-sox5kk-MuiBackdrop-root {
    z-index: -1 !important;
  }

  .css-um19vb {
    z-index: -1 !important;
  }

  .navbar-brand-mobile {
    display: block;
    text-align: center;
  }

  .navbar-brand-mobile img {
    width: 44px;
    height: 40px;
    vertical-align: unset;
  }

  .toggleMenu-nav {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px var(--backgroundColor);
    border: 0.5px solid #f0f0f0;
    border-radius: 10px;
    margin-top: 0.5rem;
    padding: 0;
  }

  .navbar-collapse {
    background-color: #e6e5e2;
    box-shadow: 0px 3px 6px #00000029;
    margin-left: -1rem;
    padding-left: 1rem;
    margin-right: -1rem;
    padding-right: 1rem;
  }

  .close-icon {
    padding-top: 35px;
    margin-right: 10px;
  }

  .search-bar {
    display: none;
    width: 55%;
    height: 30px;
    border-radius: 7px;
    border: solid 1px #ccc;
    background-color: #f7f4f2;
    margin: 0 auto;
  }

  .search-bar
    > .rectangle-search-bar
    > .search-and-discover {
    font-size: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #a8a8a8;
    display: inline;
  }

  .search-bar > .rectangle-search-bar > .MuiSvgIcon-root {
    display: inline;
  }

  .search-bar > .rectangle-search-bar > .MuiSvgIcon-root {
    color: #a8a8a8;
  }

  .profile-image {
    display: block;
    padding: 0 !important;
  }

  .profile-web {
    display: none;
  }

  .navbar .nav-item {
    padding-left: 0rem;
  }

  .navbar-toggler {
    padding: 0;
  }
  .MuiSvgIcon-root {
    font-size: 33px;
    color: #424242;
  }
}

@media (max-width: 991px) {
  .search-bar {
    display: inline !important;
  }
  .nav-item.nav-notifications .notifications-dropdown {
    width: 95vw !important;
    min-width: 93vw !important;
    max-height: 494px;
    overflow: auto;
  }
  .dropdown > .nav-link > span img,
  .nav-item > .nav-link > span > img,
  .nav-item > .nav-link > .active-link > img {
    width: 25px;
    height: 25px;
  }
  .nav-section .nav-link img {
    padding-right: 10px;
  }
}

@media (min-width: 991px) {
  .burger-icon {
    display: none;
  }
}

@media only screen and (max-width: 415px) {
}
