.content-card {
  margin: 30px 0;
  .content-card-header {
    h1 {
      font-size: 16px;
      color: #424242;
      font-weight: 600;
    }
  }
  .inside-content {
    background: #ffffff;
    width: 100%;
    padding-left: 30px;
    box-shadow: 0px 2px 4px #00000029;
    border-radius: 10px;
    .upload-image-inside-content {
      display: flex;
      .content-details {
        width: 80%;
        .content-card-title {
          padding-top: 30px;
        }
        .content-card-subtitle {
          font-size: 16px;
          color: #a3a3a3;
          padding-bottom: 30px;
        }
        .change-logo {
          padding-bottom: 30px;
          .custom-file-inputz {
            visibility: hidden;
          }
          .save-image {
            button {
              background: #f38f46;
              padding: 10px 15px;
              border-radius: 6px;
              color: #ffffff;
              outline: none;
              border: none;
            }
            :disabled {
              background-color: rgba(255, 149, 74, 0.555);
            }
          }
          label {
            color: #f38f46;
            border: none;
            background: transparent;
            outline: none;
            padding: 3px 0;
          }
          .custom-file-inputz::before {
            position: absolute;
            left: 188px;
            margin-top: -5px;
            visibility: visible;
            content: "";
            display: block;
            background: transparent;
            border: none;
            border-radius: 6px;
            padding: 20px 63px;
            outline: none;
            cursor: pointer;
            font-size: 14px;
            color: #ffffff;
          }
        }
      }
      .image-preview {
        border-radius: 10px;
        width: 20%;

        img {
          height: 100%;
          max-width: 100%;
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
    .one-field-content {
      padding: 20px 25px 30px 0;
      .one-field-content-title {
        h1 {
          font-size: 16px;
          color: #424242;
          font-weight: 600;
          span {
            color: #a3a3a3;
            font-weight: 500;
          }
        }
        p {
          color: #424242;
        }
      }
      .one-field-content-input {
        input {
          border: 1px solid #cccccc;
          width: 100%;
          padding: 10px;
          border-radius: 5px;
          outline: none;
          color: #424242;
          font-size: 16px;
        }
        textarea {
          border: 1px solid #cccccc;
          width: 100%;
          padding: 10px;
          border-radius: 5px;
          outline: none;
          color: #424242;
          font-size: 16px;
          min-height: 200px;
        }
      }
      .checkboxes-fields-section {
        display: flex;
        .row-1,
        .row-2,
        .row-3 {
          flex-basis: 30%;
          .row-title {
            h1 {
              font-size: 16px;
              color: #a3a3a3;
            }
          }
        }

        .custom-control-input:checked
          ~ .custom-control-label::before {
          background-color: #f38f46;
          border-radius: 2px;
          border: none;
          color: #ffffff;
          outline: none;
        }
      }
      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
    .card-body {
      .card-details {
        .social-form-group {
          .social-input-group {
            border: 1px solid #cccccc;
            width: 95%;
            height: 50px;
            display: flex;
            border-radius: 5px;
            margin: 20px 0;
            input {
              margin: 0;
              height: unset;
              border: none;
              outline: none;
            }
            .form-control:focus {
              box-shadow: none;
            }
            .social-input-group-prepend {
              width: 20%;
              display: flex;
              align-items: center;
              .input-group-text {
                border: none;
                width: 100%;
                height: 100%;
                background-color: #f7f4f2;
                color: #424242;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .content-card
    .inside-content
    .upload-image-inside-content {
    display: block;
  }
  .content-card
    .inside-content
    .upload-image-inside-content
    .image-preview {
    width: 100%;
    padding-bottom: 10px;
  }
  .content-card
    .inside-content
    .one-field-content
    .checkboxes-fields-section {
    display: block;
  }
  .css-1kfan1t-MuiFormControl-root {
    width: 100%;
  }
  .content-card .inside-content {
    padding: 10px;
  }
  .content-card
    .inside-content
    .upload-image-inside-content
    .content-details {
    width: 100%;
  }
  .content-card .inside-content .one-field-content {
    padding: 0;
  }
}
