div.term-policy-fullpage{
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;
}

div.term-policy-fullpage > nav {
  background-color: white;
  height: 100vh;
  max-height: 100vh;
  width: 20vw;
  max-width: 350px;
  padding: 16px 0;
}

div.term-policy-fullpage > nav > ol > li {
  padding: 5px 0;
  cursor: pointer;
}

section.term-policy-section{
    padding: 16px;
    width: 75vw;
    height: 100%;
    max-height: 100vh;
    overflow: scroll;
}
pre{
  margin-left: 24px;
  white-space: pre-wrap;
  font-family: "Open Sans", sans-serif;
}
pre.term-policy-main-text{
    font-weight: 500;
}

.term-policy-header{
  display: flex;
  align-items: center;
  margin: 0 0 16px;
  max-width: max-content;
  cursor: pointer;
}
.term-policy-header > h4{
  margin: 0 0 0 4px;
}

.term-policy-block{
  margin-bottom: 24px;
}
.term-policy-block > h5{
  font-weight: bold;
  margin-bottom: 10px;
}

