.activityParticipants {
  margin-top: 50px;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 40px;
  box-shadow: 0px 2px 4px #00000029;
}

.activityParticipants .headerTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  padding-top: 10px;
  border-bottom: 1px solid #cccccc;
}
.activityParticipants .headerTitle > h1 {
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.activityParticipants .headerTitle > .viewAllButton {
  width: 90%;
  text-align: end;
}

.activityParticipants
  .headerTitle
  > .viewAllButton
  > button {
  color: #f38f46;
  background: none;
  border: none;
}

.activityParticipants .body .participantsDetails {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.participantItem {
  margin: 5px;
  text-align: center;
}

.participantItem .avatar {
  width: 100px;
  height: 100px;
  background: #e8daca 0% 0% no-repeat padding-box;
  border-radius: 50%;
}

.activityParticipants
  .body
  .participantsDetails
  .participantItem
  > .avatar
  > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.activityParticipants
  .body
  .participantsDetails
  .participantItem
  > .avatar
  > h1 {
  font-size: 30px;
  text-align: center;
  align-items: center;
  justify-items: center;
  padding-top: 30%;
  color: #4b515d;
}

.activityParticipants
  .body
  .participantsDetails
  .participantItem
  > h1 {
  font-size: 15px;
  color: #4b515d;
  padding: 10px 0;
}

@media (max-width: 599px) {
  .activityParticipants {
    margin: 0;
    background: transparent;
    border-radius: 10px;
    padding: 10px 0;
    box-shadow: none;
  }

  .activityParticipants .headerTitle {
    margin: 0;
    background: transparent;
    padding: 5px 0;
    box-shadow: none;
  }

  .activityParticipants .headerTitle > h1 {
    font-size: 13px;
    text-align: center;
    font-weight: 600;
  }

  .participantItem {
    margin: 5px;
    text-align: center;
  }
  .participantItem .avatar {
    width: 65px;
    height: 65px;
  }

  .activityParticipants .body .participantsDetails {
    max-height: 125px;
    overflow: hidden;
  }

  .activityParticipants
    .body
    .participantsDetails
    .participantItem
    > .avatar
    > img {
    width: 65px;
    height: 65px;
  }
  .activityParticipants
    .body
    .participantsDetails
    .participantItem
    > .avatar
    > h1 {
    font-size: 20px;
  }

  .activityParticipants
    .body
    .participantsDetails
    .participantItem
    > h1 {
    font-size: 10px;
  }
}
