.contribution-card {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 4px #0000000d;
  border-radius: 10px;
  padding: 1rem 0;
  cursor: pointer;
  .inside-content {
    padding: 0 15px !important;
    margin: 0 !important;
    .contribution-details {
      h1 {
        font-size: 16px;
        color: #424242;
        font-weight: 600;
      }
      p {
        font-size: 13px;
        color: #424242;
        padding: 0 !important;
      }
    }
    .flex-content {
      display: flex;
      width: 100%;
      .status-div {
        width: 70%;
        button {
          width: 79px !important;
          height: 28px !important;
          border-radius: 17px !important;
          outline: none;
          border: none;
          font-size: 13px !important;
        }
      }
      .comment-toggle-icon {
        width: 10%;
        margin-left: 7.5rem;
        position: absolute;
        .comment-icon {
          cursor: pointer;
          img {
            margin: 0 !important;
          }
          .comment-number {
            margin-left: 2px;
            font-size: 14px;
            color: #979797;
          }
        }
        .toggledDiv {
          height: 100px;
          border: 1px solid red;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .contribution-card {
    padding-top: 5px;
    padding-left: 20px;
    text-align: start;
    .inside-content {
      padding: 0 !important;
      h1 {
        font-size: 13px;
        color: #424242;
        font-weight: 600;
        margin: 5px;
      }
      p {
        font-size: 11px;
        color: #424242;
        padding: 0 !important;
        margin: 5px;
      }
    }

    .flex-content {
      .status-div {
        button {
          width: 70px !important;
          height: 21px !important;
          font-size: 11px !important;
        }
      }
      .comment-toggle-icon {
        width: 20% !important;
        position: absolute;
        .comment-icon {
          cursor: pointer;
          img {
            width: 20px;
            margin: 0 !important;
            height: auto;
          }
          .comment-number {
            margin-left: 2px;
            font-size: 14px;
            color: #979797;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .contribution-card
    .inside-content
    .flex-content
    .comment-toggle-icon {
    width: 88% !important;
  }
}
