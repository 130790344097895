.search-page {
  padding-top: 5rem;
}

#search-component {
  display: flex;
  justify-content: center;
}

#search-component .form {
  width: 95%;
}

#search-component input,
#search-component #search-btn {
  background: #ddd5cc 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border: none;
  height: 4rem;
}

.row-results {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.see-all {
  cursor: pointer;
  text-decoration: underline;
}

.carousel-control-prev {
  margin-left: -10rem;
}

.carousel-control-next {
  margin-right: -10rem;
}

.slick-list .slick-track {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.club-link {
  color: var(--textPrimary);
  width: 25%;
}

.club-link:hover {
  color: var(--textPrimary);
}

.footer-block {
  position: absolute;
  width: 100%;
}

.footer-component {
  position: absolute;
  width: 100%;
  z-index: -1;
}

@media only screen and (max-width: 540px) {
  .club-link {
    width: 100%;
  }
}
