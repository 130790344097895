.custom-promotion-card {
  margin-bottom: 1rem;
}

.custom-promotion-card__container {
  height: 100%;
}

.custom-promotion-card__image-container {
  height: 60%;
}

.custom-promotion-card__image {
  height: 60%;
  object-fit: cover
}

.custom-promotion-card__content {
  height: 100%;
  position: relative;
}

.custom-promotion-card__bottom {
  /* position: absolute; */
  bottom: 1rem;
  width: 100%;
}

.custom-promotion-card__content__title {
  font-size: 1.2rem;
  font-weight: bolder;
  display: flex;
  justify-content: space-between;
}

.custom-promotion-card .activity-stat-item {
  margin-right: 2rem;
}

.custom-promotion-card__content__body {
  text-align: justify;
  color: #424242;
  font-size: 0.9rem;
  opacity: 1;
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-promotion-card__content__club-description h6 {
  font-weight: bold;
}

.custom-promotion-card__content__club-description small {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.custom-promotion-card__content__calltoaction {
  bottom: 8px;
  right: 8px;
}

.custom-promotion-card__content__calltoaction .submit-btn {
  height: 40px;
  min-width: 10rem;
  width: auto;
}

.promotionTypeCard {
  width: 15rem;
  text-align: center;
  margin: 0 1rem;
  cursor: pointer;
}

.promotionTypeCard img {
  padding: 2rem;
}

.promotionTypeCard .card-body {
  border-top: .1rem solid #0001;
}

select#action-type-dropdown {
  min-height: 56px;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  border: none;
  width: 100%;
  outline: none;
  margin-top: 1rem;
}

.club-summary__container {
  padding: 4px;
  color: #A3A3A3;
}

.club-summary__campaign {
  margin: 4px;
  width: 15px;
}

.club-summary__user {
  margin: 4px;
  width: 12px;
}

.promoTemp {
  padding: 16px;
  background-color: #f98f4836;
  margin: 5px 0;

}

.promoTemp__article {
  height: fit-content;
}

.promoTemp__others {
  height: 162px;
}

.promoTemp__container {
  background-color: white;
  box-shadow: 0px 3px 5px #00000040;
  padding: 10px;
  border-radius: 10px;
}

.card-img {
  background-color: #E8DACA;
  border-radius: 5px 5px 0 0;
  display: grid;
  place-items: center;
  height: 100%;
}

/* Small devices (landscape phones, 320px and up) */
@media (min-width: 320px) {
  .custom-promotion-card {
    height: fit-content;
  }

  .custom-promotion-card__container {
    display: block;
  }

  .custom-promotion-card__image {
    height: 150px;
  }

  .custom-promotion-card__bottom {
    position: unset;
  }

  .custom-promotion-card__content__body {
    min-height: fit-content;
  }

  .custom-promotion-card__content__calltoaction {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .custom-promotion-card__content__calltoaction.link {
    display: none;
  }

  .custom-promotion-card__bottom>div.activity-stats.justify-content-between.flex-column-reverse.flex-xl-row>div.d-flex.align-items-center.mt-2 {
    justify-content: space-around;
  }

  .card-img >img {
    max-height: 100%;
    max-width: 100%;
    height: 6rem;
  }
  .promotion-section {
    display: block !important;
  }
  .promotionTypeCard {
    width: 100%;
    margin: 10px 0;
    align-items: center;
  }

  .promotionTypeCard > .card-img-top {
    width: 8rem !important;
  }
  .promotionTypeCard > .card-body {
    width: 100%;
  }
  .promotionTypeCard > .card-body > .card-text:last-child {
    text-align: center;
    font-size: 1rem;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .custom-promotion-card__content__calltoaction.link {
    display: block;
  }
  .custom-promotion-card__container {
    height: 150px;
    display: flex;
}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .custom-promotion-card__container {
    height: 100px;
    display: flex;
  }

  .custom-promotion-card__image-container {
    width: 20%;
    height: auto;
    padding-right: 12px;
  }

  .custom-promotion-card__image {
    height: 100%;
  }

  .custom-promotion-card__content {
    width: 100%;
  }

  .custom-promotion-card__content__body {
    height: 80px;
    -webkit-line-clamp: 3;
  }

  .custom-promotion-card__bottom {
    /* position: absolute; */
    bottom: 1rem;
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .custom-promotion-card__image-container {
    padding-right: 12px;
  }
  .custom-promotion-card__container {
    height: 150px;
    display: flex;
  }
  .card-img >img {
    max-height: 100%;
    max-width: 100%;
    height: 6rem;
  }
  .promotion-section {
    display: flex !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .custom-promotion-card__container {
    height: 150px;
    display: flex;
}

.card-img >img {
  max-height: 100%;
  max-width: 100%;
  height: 10rem;
}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1600px) {}