.admin-club-members {
  width: 90% !important;
  margin: 0 auto;
  margin-top: 100px;
  .title {
    display: flex;
    padding: 30px 0;
    .club-name {
      display: flex;
      width: 89%;
      h1 {
        font-size: 28px;
        color: #424242;
        font-weight: 900;
      }
    }
  }
  .club-members-header {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
    .small-screens {
      display: none;
    }
    .activities-count {
      width: 60%;
      p {
        margin: auto 0;
      }
    }
    .search-activities {
      width: 40%;
    }
  }
  .admin-club-members-section {
    display: flex;
    flex-wrap: wrap;
    .admin-member-card {
      background: #ffffff;
      width: 287px;
      height: 110px;
      margin: 20px;
      margin-left: 0;
      border-radius: 10px;
      .member-card-inside-content {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        box-shadow: 0px 2px 4px #0000000d;
        border-radius: 10px;
        padding: 0 !important;
        .delete-member,
        .edit-member {
          height: 100%;
          padding: 5px 5px;
          button {
            border: none;
            background: transparent;
            padding: 0;
            outline: none;
            .MuiSvgIcon-root {
              width: 1.3rem;
              height: 1.3rem;
              color: rgba(0, 0, 0, 0.514);
            }
          }
        }
        .edit-member {
          margin: 0;
          button {
            margin: 0;
            padding: 0;
          }
        }
        .admin-member-avatar {
          width: 50px;
          height: 50px;
          margin-right: 5px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .admin-member-details {
          width: 63%;
          h1 {
            font-size: 16px;
            color: #424242;
          }
          p {
            font-size: 13px;
            color: #a3a3a3;
            margin: auto 0;
          }
        }
      }
    }
  }
}
.edit-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  .edit-modal-content {
    outline: none;
    background: #ffffff;
    width: 30%;
    height: 18%;
    border-radius: 10px;
    .modal-title {
      border-bottom: 2px solid #42424231;
      padding-left: 10px;
      padding-top: 5px;
      h1 {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;
      button {
        width: 100% !important;
        padding: 10px;
        text-align: start;
        font-size: 14px;
        border: none;
        background: transparent;
        outline: none;
      }
      :disabled {
        color: unset;
        background: #4242422c;
      }
    }
  }
}

.delete-confirmation-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  .delete-confirmation-modal-content {
    outline: none;
    background: #ffffff;
    width: 500px;
    height: 190px;
    border-radius: 10px;
    .delete-confirmation-modal-title {
      padding: 0 25px;
      padding-top: 20px;
      h1 {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .delete-confirmation-modal-body {
      padding: 10px 25px;
      p {
        font-size: 13px;
      }
    }
    .delete-confirmation-call-to-action-buttons {
      border-top: 1px solid #0000001e;
      padding: 10px 15px;
      .confirmation-cancel-button {
        float: right;
        height: 100%;
        padding-right: 10px;
        width: 170px;
        height: 40px;
        button {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          outline: none;
          color: #e0995d;
        }
      }
      .confirmation-remove-button {
        float: right;
        width: 170px;
        height: 40px;
        button {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          box-shadow: 0px 3px 5px #00000040;
          background: #f38f46;
          color: #ffffff;
          border: none;
          outline: none;
        }
      }
    }
  }
}

@media (max-width: 682px) {
  .admin-club-members-section {
    display: block;
    .delete-member,
    .edit-member {
      padding: 10px !important;
    }
    .admin-member-card {
      width: 100% !important;
      margin: 0 !important;
      margin: 10px 0 !important;
      .member-card-inside-content {
        justify-content: unset !important;
        padding-left: 20px;
        .admin-member-details {
          width: 62% !important;
        }
      }
    }
  }
  .edit-modal {
    .edit-modal-content {
      width: 90%;
      .modal-title {
        h1 {
          font-size: 16px;
        }
      }
    }
  }
  .delete-confirmation-modal-content {
    width: 90% !important;
  }
}

@media (max-width: 590px) {
  .admin-club-members {
    margin-top: 80px !important;

    .bigger-screens {
      display: none !important;
    }
    .title {
      display: block;

      .club-name {
        h1 {
          color: #424242;
          font-weight: 600;
          font-size: 16px;
        }
      }
      .edit-button {
        width: 100%;
        padding-top: 30px;
      }
    }
    margin-top: 100px;
    padding: 0;
    .club-members-header {
      display: inline-block;
      margin-top: 0;
      .small-screens {
        display: block;
        margin-top: 20px;
      }
      .activities-count {
        width: 100%;
        display: flex;
        p {
          margin: auto 0;
          width: 50%;
          font-size: 13px;
        }
        .edit-button {
          width: 50%;
          button {
            width: 100%;
            height: 30px;
            margin: auto 0;
            padding: 0;
            border-radius: 7px;
            font-size: 13px;
          }
        }
      }
      .search-activities {
        width: 100%;
      }
    }
  }
  .admin-club-members-section {
    .member-card-inside-content {
      .admin-member-details {
        width: 72% !important;
      }
    }
  }
  .delete-confirmation-modal-content {
    height: 13rem !important;
  }
}

@media (max-width: 411px) {
  .member-card-inside-content {
    margin: 0;
    .delete-member,
    .edit-member {
      margin: 0;
    }
    .admin-member-avatar {
      height: 47px !important;
    }
  }
  .delete-confirmation-modal-content {
    height: 16rem !important;
    .confirmation-cancel-button,
    .confirmation-remove-button {
      float: none !important;
      width: 100% !important;
    }
  }
}
