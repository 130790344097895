body {
  background-color: #f7f4f2;
}

.landing-container {
  max-width: 100%;
  margin: 0 auto;
}

/* --------------------------------------------------------------------------

   landing introduction section

-------------------------------------------------------------------------- */
.landingIntro {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.defaultBackground::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 900px;
  z-index: -1;
  background: url('../assets/bg-wave.svg');
  background-repeat: no-repeat;
  transform: scaleX(-1);
  z-index: -1;
}

.landingIntro .leftDiv {
  width: 50%;
  background: #f7f4f2;
}

.landingIntro .leftDiv .small-screens {
  display: none;
}

.landingIntro .leftDiv .content {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 40px;
}

.landingIntro .leftDiv .content > img {
  width: 120px;
}

.landingIntro .leftDiv .content > h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  line-height: 48px;
  margin: 20px 0;
  letter-spacing: 0.5px;
  width: 413px;
}

.landingIntro .leftDiv .content > p {
  line-height: 30px;
  font-size: 20px;
  margin: 40px 0;
  white-space: pre-line;
}

.landingIntro .leftDiv .content .actionButtons button {
  font-size: 16px;
  padding: 9px 43px;
  margin-right: 10px;
  border-radius: 10px;
  outline: none;
  background: #f38f46;
  color: #ffffff;
  border: none;
}

.landingIntro .rightDiv {
  position: relative;
  width: 50%;
}

.landingIntro .rightDiv .content {
  height: 100%;
}

.landingIntro .rightDiv .content > hr {
  max-width: 70px;
  width: 70px;
  margin-top: 0;
  padding: 0;
  margin: 0 20px;
}
.landingIntro .rightDiv .content > .site-slogan {
  position: relative;
  bottom: 60px;
  height: 50px;
  padding: 0 30px;
}
.landingIntro .rightDiv .content > .site-slogan > h1 {
  font-size: 16px;
  color: #ffffff;
  line-height: 26px;
  font-weight: 600;
  padding: 0;
  margin: auto 0;
  letter-spacing: 0.5px;
}

.landingIntro .rightDiv .content > .background-image {
  width: 100%;
  height: 100%;
}

.landingIntro .rightDiv .content > .background-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* --------------------------------------------------------------------------

   landing About section

-------------------------------------------------------------------------- */
.landingAbout {
  background-color: #ffffff;
  width: 100%;
  text-align: left;
}

.landingAbout p,
.landingAbout h1 {
  font-size: 16px;
  color: #424242;
  text-align: justify;
}

.landingAbout > .whatWeDo,
.landingAbout > .howWeDoIt {
  display: flex;
  width: 100%;
  padding: 50px;
  align-items: center;
}

.landingAbout > .howWeDoIt {
  flex-direction: row-reverse;
}

.landingAbout .whatWeDo > .rightDiv {
  width: 500px;
  margin-left: 15%;
}

.landingAbout .howWeDoIt > .leftDiv {
  width: 500px;
  margin-left: 15% !important;
}

.landingAbout .whatWeDo > .rightDiv > h1,
.landingAbout .howWeDoIt > .rightDiv > h1 {
  font-size: 25px;
  font-weight: 600;
  margin: 20px 0;
}

.landingAbout .whatWeDo > .leftDiv,
.landingAbout .howWeDoIt > .rightDiv {
  width: 50%;
}

.landingAbout .howWeDoIt .rightDiv > p {
  width: 500px;
}
.landingAbout .whatWeDo .leftDiv > img,
.landingAbout .howWeDoIt .leftDiv > img {
  width: 503px;
  height: 244px;
}

/* --------------------------------------------------------------------------

   Landing Footer section

-------------------------------------------------------------------------- */
.landingFooter {
  padding: 100px 100px 20px;
}

.landingFooter .footerDetails {
  display: flex;
}
.landingFooter > .footerDetails > div {
  flex: 1 1 auto;
  text-align: left;
  margin: 30px;
}

.landingFooter > .footerDetails > .overview > h1 {
  font-size: 20px;
  font-weight: 600;
  color: #424242;
}

.landingFooter > .footerDetails > .footerDescription > p,
.landingFooter > .footerDetails > .overview > p {
  margin-top: 10px;
}
.landingFooter > .footerDetails > .overview > ul {
  margin: none;
  padding: 0;
  list-style-type: none;
}

.landingFooter > .footerDetails > .footerSlogan {
  max-width: 350px;
  text-align: justify;
}

.landingFooter > .footerDetails > .footerSlogan button {
  border: 1px solid #f38f46;
  border-radius: 10px;
  width: 199px;
  height: 40px;
  color: #f38f46;
  background: transparent;
  outline: none;
  box-shadow: 0px 3px 5px #00000040;
}

.landingFooter
  .footerDetails
  .footerDescription
  .socialMediaFooter
  > a
  > img {
  max-width: 20px;
  height: 14px;
  margin: 0 14px;
}

/* --------------------------------------------------------------------------

copyright

-------------------------------------------------------------------------- */
.landingCopyright {
  text-align: center;
  background-color: #f38f46;
  border: #c6a177;
  color: #424242;
  height: 57px;
  align-items: center;
  padding-top: 15px;
}
.landingCopyright p {
  text-align: center;
}
/* --------------------------------------------------------------------------

   reusable styles

-------------------------------------------------------------------------- */
.color-orange {
  color: #f38f46;
}
/* --------------------------------------------------------------------------

    media queries for bigger screens

-------------------------------------------------------------------------- */
@media screen and (min-width: 1700px) {
  /* --------------------------------------------------------------------------

   landing introduction section

-------------------------------------------------------------------------- */
  .landingIntro .leftDiv .content {
    margin: 170px 200px;
    width: 100%;
  }

  .landingIntro .rightDiv .content > h1 {
    font-size: 16px;
    color: #ffffff;
    line-height: 26px;
    font-weight: 600;
    width: 545px;
    text-align: center;
    padding: 0 30px;
    letter-spacing: 0.5px;
  }
  .landingIntro .rightDiv .content > hr {
    max-width: 70px;
    width: 70px;
    margin-top: 0;
    padding: 0;
    margin: 0 20px;
  }
}

/* --------------------------------------------------------------------------

    media queries for small laptops

-------------------------------------------------------------------------- */
@media screen and (max-width: 1040px) {
  /* --------------------------------------------------------------------------

   landing introduction section

-------------------------------------------------------------------------- */
  .landingIntro {
    width: 100%;
    height: 100vh;
  }
  .landingIntro::before {
    content: '';
    position: absolute;
    background-size: 600px;
  }

  .landingIntro .leftDiv .content {
    width: 500px;
    height: 497px;
    margin: 50px 50px;
  }

  .landingIntro .leftDiv .content > h1 {
    font-size: 20px;
    width: 355px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .landingIntro .leftDiv .content > p {
    font-size: 15px;
    margin-top: 20px;
  }

  .landingIntro .leftDiv .content .actionButtons button {
    font-size: 12px;
  }

  .landingIntro .rightDiv .content > h1 {
    font-size: 12px;
    line-height: 23px;
    letter-spacing: 0;
  }
  /* --------------------------------------------------------------------------

   landing About section

  -------------------------------------------------------------------------- */
  .landingAbout {
    width: 100%;
    padding: 0;
  }

  .landingAbout .whatWeDo > .rightDiv {
    width: 500px;
    margin-left: 15%;
  }

  .landingAbout .howWeDoIt .rightDiv > p {
    width: 300px;
  }
  .landingAbout .whatWeDo .leftDiv > img,
  .landingAbout .howWeDoIt .leftDiv > img {
    width: 300px;
    height: 150px;
  }

  /* --------------------------------------------------------------------------

   Landing Footer section

-------------------------------------------------------------------------- */
  .landingFooter {
    padding: 50px;
  }

  .landingFooter > .footerDetails > div {
    flex: 1 1 auto;
    text-align: left;
    width: 100%;
  }

  .landingFooter > .footerDetails > .overview > h1 {
    font-size: 16px;
  }
}

/* --------------------------------------------------------------------------

    media queries for tablet

-------------------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  /* --------------------------------------------------------------------------

   landing introduction section

-------------------------------------------------------------------------- */
  .landingIntro {
    height: 100vh;
    width: 100%;
  }
  .landingIntro::before {
    margin-left: 223px;
  }

  /* --------------------------------------------------------------------------

   landing About section

-------------------------------------------------------------------------- */
  .landingAbout {
    width: 100%;
    padding: 0;
  }

  .landingAbout .whatWeDo > .rightDiv {
    width: 500px;
    margin-left: 15%;
  }

  .landingAbout .howWeDoIt .rightDiv > p {
    width: 300px;
  }
  .landingAbout .whatWeDo .leftDiv > img,
  .landingAbout .howWeDoIt .leftDiv > img {
    width: 300px;
    height: 150px;
  }
  .landingIntro .leftDiv .content > p {
    margin: 15px 0;
  }
  .actionButtons button {
    font-size: 10px !important;
    padding: 4px 25px !important;
    margin: 0 !important;
  }
  /* --------------------------------------------------------------------------

   Landing footer section

  -------------------------------------------------------------------------- */

  .landingFooter {
    width: 100%;
    padding: 0;
  }
  .landingCopyright {
    width: 100%;
  }
}

/* --------------------------------------------------------------------------

    media queries for phones

-------------------------------------------------------------------------- */
@media screen and (max-width: 500px) {
  /* --------------------------------------------------------------------------

   landing introduction section

-------------------------------------------------------------------------- */
  .landingIntro {
    display: block;
    width: 100%;
    height: 100vh;
    background: url('../assets/mobile-bg.svg');
    background-repeat: no-repeat;
    background-size: 10px;
  }

  .bigger-screens {
    display: none;
  }

  .landingIntro::before {
    display: none;
  }

  .landingIntro .leftDiv .small-screens {
    display: block;
    height: 100%;
  }

  .landingIntro .leftDiv {
    width: 100%;
    background: transparent;
    height: 890px;
  }

  .landingIntro .leftDiv .content {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .landingIntro .leftDiv .content > img {
    width: 120px;
    display: flex;
    text-align: center;
    margin: auto;
    padding: 40px 0;
  }

  .landingIntro .leftDiv .content > h1 {
    font-size: 16px;
    text-align: center;
    line-height: normal;
    margin: 0;
    letter-spacing: 0;
    width: 100%;
    padding: 10px 0;
    color: #ffffff;
  }

  .landingIntro .leftDiv .content > p {
    line-height: 30px;
    font-size: 14px;
    margin: 0;
    text-align: center;
    padding: 10px 0;
    color: #ffffff;
    font-weight: 600;
    padding: 40px 0;
  }

  .landingIntro .leftDiv .content .actionButtons {
    margin-top: 16px;
    display: block;
    width: 100%;
    margin: 0 !important;
    margin: 0 auto !important;
    text-align: center;
  }
  .landingIntro .leftDiv .content .actionButtons button {
    font-size: 14px !important;
    padding: 0;
    width: 90% !important;
    height: 38px;
    border-radius: 10px;
    outline: none;
    background: #f38f46;
    color: #ffffff;
    border: none;
    margin-top: 2px;
    width: 100%;
  }
  .landingIntro
    .leftDiv
    .content
    .actionButtons
    > .loginButton
    > button {
    color: #ffffff !important;
  }
  .landingIntro .rightDiv {
    display: none;
  }

  /* --------------------------------------------------------------------------

   landing About section

-------------------------------------------------------------------------- */
  .landingAbout {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  .landingAbout p,
  .landingAbout h1 {
    font-size: 14px !important;
    padding: 0 20px;
    text-align: center !important;
  }

  .landingAbout > .whatWeDo,
  .landingAbout > .howWeDoIt {
    display: block;
    padding: 0;
    padding: 30px 0;
  }

  .landingAbout .whatWeDo > .rightDiv {
    width: 100%;
    margin-left: 0;
  }

  .landingAbout .howWeDoIt > .leftDiv {
    width: 100%;
    margin-left: 0 !important;
  }

  .landingAbout .whatWeDo > .leftDiv,
  .landingAbout .howWeDoIt > .rightDiv {
    width: 100%;
  }

  .landingAbout .whatWeDo > .rightDiv > h1,
  .landingAbout .howWeDoIt > .rightDiv > h1 {
    font-size: 14px;
  }

  .landingAbout .howWeDoIt .rightDiv > p {
    width: 100%;
  }
  .landingAbout .whatWeDo .leftDiv > img,
  .landingAbout .howWeDoIt .leftDiv > img {
    width: 187px;
    height: 112px;
  }

  /* --------------------------------------------------------------------------

   Landing Footer section

  -------------------------------------------------------------------------- */
  .landingFooter {
    padding: 0;
    width: auto;
  }

  .landingFooter .footerDetails {
    display: block;
  }
  .landingFooter > .footerDetails > div {
    flex: 0;
    text-align: center;
    width: auto;
  }

  .landingFooter > .footerDetails > .footerDescription > h1,
  .landingFooter > .footerDetails > .overview > h1 {
    font-size: 14px;
  }

  .landingFooter > .footerDetails > .footerDescription {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .landingFooter > .footerDetails > .footerDescription > p,
  .landingFooter > .footerDetails > .overview > p {
    font-size: 14px;
    width: 100%;
    text-align: center;
  }

  .landingFooter > .footerDetails > .overview > ul > li {
    padding: 5px 0;
    font-size: 14px;
  }

  .landingFooter > .footerDetails > .footerSlogan {
    font-size: 14px;
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .landingFooter > .footerDetails > .footerSlogan button {
    border: 1px solid #f38f46;
    border-radius: 10px;
    width: 199px;
    height: 40px;
    color: #f38f46;
    background: transparent;
    outline: none;
    box-shadow: 0px 3px 5px #00000040;
  }
  .landingCopyright {
    text-align: center;
    background-color: #f38f46;
    border: #c6a177;
    color: #424242;
    height: 29px;
    align-items: center;
    padding-top: 7px;
    width: auto;
  }
  .landingCopyright > p {
    font-size: 10px;
  }
}

@media screen and (max-width: 320px) {
  /* --------------------------------------------------------------------------

   landing introduction section

-------------------------------------------------------------------------- */
  .landingIntro .leftDiv .content .actionButtons {
    margin: 10px 70px;
  }
}
