.web-content-container-fluid {
  display: flex;
  width: 100%;
  margin-top: 2rem;
  border-bottom: 3px solid #cccccc;
  .web-content-side-nav-item {
    color: var(--textPrimary) !important;
    margin-right: 1rem;
    outline: none;
    position: relative;
    top: 3px;
    z-index: 2;
  }

  .web-content-nav-item__active {
    color: var(--primaryColor) !important;
    border-bottom: 3px solid var(--primaryColor);
  }
}
.web-content-container-fluid-responsiveness,
.title-responsiveness {
  display: none;
}

@media (max-width: 768px) {
  .web-content-container-fluid {
    display: none;
  }
  .title-responsiveness {
    display: block;
  }
  .nav-bar {
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0px 2px 4px #00000059;
  }
  .web-content-container-fluid-responsiveness {
    display: block;
    padding: 0;
    .web-content-side-nav-item {
      color: var(--textPrimary) !important;
      margin-right: 0;
      outline: none;
      position: relative;
      top: 3px;
      z-index: 2;
    }
    .web-content-nav-item__active {
      color: var(--primaryColor) !important;
    }
  }
  .web-content-header {
    padding: 10px;
    .manage-web-content-title {
      h1 {
        font-size: 16px;
        font-weight: 600;
      }
    }
    button {
      width: 100%;
      color: #ffffff;
      background-color: #f38f46;
      border: none;
      outline: none;
      border-radius: 7px;
      padding: 10px;
      margin-top: 10px;
    }
  }
}
