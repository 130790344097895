.pagination {
  width: 100%;
  padding: 20px 0;
  .items-per-page {
    margin: auto 0;
    p {
      font-size: 15px;
      margin: 0;
    }
  }
  .navigate-buttons {
    margin-left: auto;
    button {
      border: none;
      outline: none;
      background: transparent;
      color: #4242429c;
      .navigate-button {
        width: 32px;
        height: 32px;
      }
    }
    :disabled {
      color: #42424249;
    }
    select {
      width: 66px;
      height: 32px;
      border: none;
      border-radius: 0.5px;
      background: #ffffff;
      color: #424242;
      outline: none;
    }
  }
}

@media (max-width: 590px) {
  .pagination {
    display: flex;
    flex-direction: column;
    .items-per-page {
      p {
        font-size: 13px;
      }
    }
    .navigate-buttons {
      margin-left: 0;
      padding: 20px 0;
      button {
        border: none;
        outline: none;
        background: transparent;
        color: #4242429c;
      }
      :disabled {
        color: #42424249;
      }
      select {
        width: 66px;
        height: 25px;
        border: none;
        border-radius: 0.5px;
        background: #ffffff;
        color: #424242;
        outline: none;
      }
    }
  }
}
