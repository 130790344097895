a.activityLinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    text-decoration: none;
    width: 100%;
    padding: 15px 0;
    margin: 40px 0 0;
    .text{
        width: 80%;
        h3, p{
            font-size: 13px;
        }
        h3{
            font-weight: bold;
            color: #424242;
            span{
                color: #A3A3A3;
            }
        }
        p{
            color: #4B515D;
            margin: 0;
        }
    }
    svg{
        color: #F38F46;
    }
    @media (min-width: 600px){
        .text{
            h3, p{
                font-size: 16px;
            }
        }
    }
}