.nav-link.side-nav-item {
  color: var(--textPrimary);
  margin-bottom: 1rem;
}

.nav-link.side-nav-item__active {
  color: var(--primaryColor);
  border-left: 5px solid var(--primaryColor);
}

.helper-text {
  font-size: 13px;
  color: var(--textSecondary);
  text-align: left;
  padding: 0 1rem;
}

.back-link {
  color: var(--primaryColor);
  position: absolute;
  bottom: 1rem;
  margin-left: 1rem;
}

@media only screen and (max-width: 1023px) {
  .back-link {
    position: relative;
    margin-top: 2rem;
    margin-left: 0;
    bottom: unset;
  }
}
