.output-sources-section {
  .output-sources-description {
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .output-sources-details {
    .MuiFormControl-root {
      width: 200px;
    }
  }
  .add-another-source {
    margin-top: 40px;
    color: #f38f46;
    display: flex;
    align-items: center;
    button {
      border: none;
      background-color: transparent;
      outline: none;
      font-size: 16px;
      color: #f38f46;
    }
  }
  .action-cancel {
    display: flex;
    align-items: center;
    .cancel-button {
      height: 50%;
      width: 15%;
      margin: 0 10px;
      color: #f38f46;
      cursor: pointer;
    }
  }
}
