.profile-page {
  background: transparent linear-gradient(317deg, #c7c3ba 0%, #e8e7e3 100%) 0%
    0% no-repeat padding-box;
  background-image: url("../assets/footer.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding-top: 5rem;
}

.profile-left-name {
  font-size: 25px;
  font-weight: bold;
  color: var(--textPrimary);
}

.profile-left-details {
  font-size: 20px;
  color: var(--textSecondary);
}

.profile-card-sm {
  height: 75px;
  background: #f7f4f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px #00000029;
  border-radius: 3px;
  margin-top: 1rem;
}

.profile-card-sm.card-sm-active {
  background-color: #e8daca;
}

.profile-card-sm:hover {
  cursor: pointer;
}

.my-profile {
  background: #f7f4f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px #00000029;
  border-radius: 5px;
  margin-top: 1rem;
  min-height: 500px;
}

.profile-card-sm .card-title,
.my-profile .card-title {
  font: Regular 25px/60px Open Sans;
  color: var(--textPrimary);
}

.card-sm-right {
  font: Regular 20px/60px Open Sans;
  color: var(--textSecondary);
}

.my-profile-picture img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #e6e5e2;
}

.my-profile-label {
  font: Regular 15px/60px Open Sans;
  color: #a8a8a8;
}

.my-profile-actions:hover {
  cursor: pointer;
  text-decoration: none;
}

.my-profile-value {
  font: Regular 20px/60px Open Sans;
  color: var(--textPrimary);
}

.picture-upload-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  min-height: 300px;
  border: 2px dashed gray;
  border-radius: 10px;
}

.modal-file-upload {
  width: 60%;
}

.list-items {
  display: flex;
  flex-wrap: wrap;
}

.item-card {
  width: calc(33.3% - 8px);
  margin: 0.5rem 4px;
  /* height: 200px; */
}

.item-card img {
  border-radius: 5px 5px 0px 0px;
}

.item-card:hover {
  cursor: pointer;
}

.item-card .card-body img {
  width: 25px;
}

.card-hr {
  border: 1px solid #efdac3;
  background-color: #efdac3;
}

.roles-dropdown-button {
  box-shadow: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
  margin-left: -0.75rem;
}

.checkbox-menu {
  width: 100%;
}

.checkbox-menu li label {
  display: block;
  padding: 3px 10px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  margin: 0;
  transition: background-color 0.4s ease;
}
.checkbox-menu li input {
  margin: 0px 5px;
  top: 2px;
  position: relative;
}

.checkbox-menu li.active label {
  background-color: #cbcbff;
  font-weight: bold;
}

.checkbox-menu li label:hover,
.checkbox-menu li label:focus {
  background-color: #f5f5f5;
}

.checkbox-menu li.active label:hover,
.checkbox-menu li.active label:focus {
  background-color: #b8b8ff;
}

.dropdown-menu.checkbox-menu [type="checkbox"] {
  position: unset;
}

.table-block {
  background: #ffffff;
  box-shadow: 0px 2px 4px #00000029;
  border-radius: 10px;
}

.table thead {
  background: #f8f5f2;
}

.table td {
  vertical-align: middle;
}

/* Media Queries */
@media only screen and (max-width: 1024px) {
  .email-cell {
    display: none;
  }
}

@media only screen and (max-width: 415px) {
  .profile-card-sm .card-title,
  .my-profile .card-title {
    font-size: 18px;
  }

  .card-sm-right {
    font-size: 14px;
  }

  .my-profile {
    min-height: 350px;
  }

  .my-profile-picture img {
    width: 99px;
    height: 99px;
  }

  .profile-left-name {
    font-size: 22px;
  }

  .profile-left-details {
    font-size: 16px;
  }

  .profile-card-sm {
    height: 60px;
  }

  .my-profile-label {
    font-size: 9px;
  }

  .my-profile-value {
    font-size: 12px;
  }

  .item-card {
    width: 44%;
    margin: 0.5rem 0.5rem;
    /* height: 200px; */
  }

  .item-card .card-body {
    padding: 1rem 0.5rem;
  }

  .item-card .card-body img {
    width: 14px;
  }

  .item-card .card-body .card-text {
    font-size: 10px;
  }
}

@media (min-width: 320px) {
  .item-card {
    width: calc(100% - 8px);
    margin: 0.5rem 4px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .item-card {
    width: calc(33.3% - 8px);
    margin: 0.5rem 4px;
  }
}
