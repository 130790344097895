.rbc-month-view {
  background-color: #FFFFFF;
}

.rbc-toolbar .rbc-toolbar-label {
  font: normal normal bold 30px/50px Open Sans;
}

.rbc-header {
  font-weight: normal !important;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-today {
  background-color: rgba(245, 130, 31, 0.5) !important;
}

.rbc-date-cell {
  text-align: center !important;
  font-weight: bold !important;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: var(--primaryColor) !important;
}

.rbc-show-more {
  color: var(--textPrimary) !important;
}

.toolbar-container {
  font-size: 30px;
}

.calendar-arrow {
  color: var(--primaryColor) !important;
  cursor: pointer;
  font-size: 150px;
}
