.add-course-lessons {
  .lesson-wrapper {
    z-index: 9999;

    .lesson-item {
      .delete-lesson-icon {
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
      }
    }
  }
}
