.create-club-header {
  color: var(--textPrimary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-club-header .header-left .header-modal-title {
  font-size: 25px;
  font-weight: 600;
}

.create-club-header .header-left .header-modal-subtitle {
  font-size: 18px;
}

.create-club-header .logo-header-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-club-header .header-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #F7F4F2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-club-header .header-logo img {
  width: 50px;
}

.create-club-header .logo-header-block .actions-buttons input[type="file"] {
  display: none;
}

.create-club-form .create-club-buttons {
  display: flex;
  justify-content: center;
}

.create-club-form .create-club-buttons button {
  margin: 0 2rem;
}

.create-club-form form > .row {
  margin-top: 0.5rem;
}

.create-club-form input,
.create-club-form textarea,
.create-club-form label {
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  border: none;
  min-height: 56px;
}

.create-club-form label {
  min-height: unset;
}

.create-club-form .help-text {
  color: #999999;
  font-size: 16px;
}

.create-club-form .status-radios {
  font-size: 18px;
  margin-bottom: 3rem;
}

.create-club-form .status-radios .form-check {
  padding-right: 15rem;
}

input[type="radio"],
input[type="checkbox"] {
  min-height: unset !important;
}


.select-club-dropdown {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px var(--backgroundColor);
  border-radius: 10px;
  padding: 0.2rem 1rem;
}

.select-club-dropdown .select-club-header {
  font-size: 12px;
  color: var(--textSecondary);
}

.select-club-dropdown .select-club-input {
  display: flex;
  font-size: 16px;
  align-items: center;
}

.select-club-dropdown .select-club-input select,
.select-club-dropdown .select-club-input select:focus {
  border: none;
  padding: 0;
  margin-left: -0.2rem;
  margin-top: -0.4rem;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: transparent;
}

/* Media Queries */
@media only screen and (max-width: 540px) {}
