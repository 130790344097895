.search-items {
  .input-group {
    #search-btn {
      background-color: #ffffff;
      border: 0.5px solid #cccccc70;
      border-left: 0;
    }
  }
  .input-group > input,
  .input-group > input:focus {
    background-color: #ffffff;
    border: 0.5px solid #cccccc70;
    border-right: 0;
    font-size: 12px;
    border-radius: 7px 0 0 7px;
    outline: none;
  }
}
