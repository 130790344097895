/* -------------------------------------------------
    Sign up page
   ------------------------------------------------- */
.auth-container {
  max-width: 1440px;
  margin: 0 auto;
}

.back-homepage {
  margin-bottom: 36px;
}

#intro {
  background-position: right !important;
}

.intro-text {
  color: whitesmoke !important;
}

.intro-text {
  position: absolute;
  bottom: 10rem;
  width: 100%;
  color: #4b515d;
}

/* ------------------------------------------------------
    Form
   ------------------------------------------------------- */
#form-block {
  margin: unset !important;
  background-image: unset;
  background-color: white;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

#form-block > div > div > div > form > div input {
  height: 48px;
  border-radius: 5px;
}

#form-block
  > div
  > div
  > div.signup-form
  > form
  > div
  select {
  height: 48px;
  background-color: transparent;
  border: #a8a8a8 solid 1px;
  border-radius: 5px;
}

/* Floating Labels */
.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
  font-size: 75%;
  transform: translate3d(4px, -50%, 0);
  opacity: 1;
  background-color: white;
  padding: 4px;
  margin-left: 4px;
}

.form-group > input:focus,
.input-group > input:focus {
  border: #e69355 solid 1px;
}

/*Radio Buttons */
[type='radio']:checked + label:before {
  border-color: #e69355;
}

[type='radio']:checked + label:after {
  background-color: #e69355;
}

#form-block > div > div > div.login-form > div > div > div {
  background: white;
}

#form-block
  > div
  > div
  > div.login-form
  > div
  > div
  > div
  > p.forgot-modal-title.text-center {
  font-size: 1.5rem;
}

#form-block
  > div
  > div
  > div.login-form
  > div
  > div
  > div
  > p.forgot-modal-text {
  font-size: 1rem;
}

/* Modal */

.form-input-container {
  width: 100%;
  padding: 0 10px;
  margin: 16px 0;
}

/* Small devices (landscape phones, 320px and up) */
@media (min-width: 320px) {
  .back-homepage {
    display: none;
  }

  #form-block > div > div > div > h5 {
    font-size: 1rem;
  }

  .what-we-do p,
  .how-we-do p {
    font-size: 16px;
  }

  #logo {
    height: 13rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  #form-block
    > div
    > div
    > div.login-form
    > form
    > div.d-flex.justify-content-end
    > small
    > p {
    color: #e69355;
  }

  .auth-comp {
    margin-top: 1rem;
  }

  .padding-auth {
    padding: 40px 0;
  }

  #intro {
    background-position: top !important;
    top: 49px;
  }

  #form-block {
    margin: unset !important;
    background-image: unset;
    background-color: white;
    border-radius: 50px 50px 0 0;
  }

  #logo > img {
    display: none;
  }

  #intro > div.intro-text {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    color: #4b515d;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .back-homepage {
    display: flex;
  }

  .login-form {
    margin-top: 50%;
  }

  #form-block {
    margin: unset !important;
    background-image: unset;
    background-color: white;
    border-radius: unset;
  }

  #intro {
    background-position: top !important;
    top: 0;
  }

  #logo > img {
    display: flex;
  }

  #intro > div.intro-text {
    position: absolute;
    top: unset;
    bottom: 10rem;
    left: 0;
    width: 100%;
    color: #4b515d;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1600px) {
}
